import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Card } from "reactstrap";

const BlogPost = ({ allBlogs, blogDetails }) => {
  const { id } = useParams();

  const navigate = useNavigate();

  // const relatedBlogs = () => {
  //   return allBlogs.filter(
  //     (blog) => blog.parentCategory === blogDetails.parentCategory
  //   );
  // };

  const relatedBlogs = () => {
    // return allBlogs.filter(
    //   (blog) => blog?.value?.[0] === blogDetails?.value?.[0]
    // );
    const ele = allBlogs.filter(
      (blog) => blog?.value?.[0] === blogDetails?.value?.[0]
    );
    return ele;
  };

  useEffect(() => {
    relatedBlogs();
  }, []);

  const handleClick = () => {
    toast.remove();
    toast.error("Work in progress!");
  };

  const handleSwiperSlideBlogs = (id) => {
    navigate(`/blogdetails/${id}`);
  };

  return (
    <>
      <div className="mt-5">
        <h5 className="border-bottom pb-3">Related Blog Posts</h5>
        <Swiper
          loop={true}
          slidesPerView={2}
          spaceBetween={20}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          autoHeight={true}
          pagination={{ clickable: true }}
          className="pb-5 mt-4"
          modules={[Autoplay, Pagination]}
        >
          {(relatedBlogs() || []).map((blogPostDetails) => (
            <SwiperSlide key={blogPostDetails._id}>
              <Card
                className="blog-modern-box overflow-hidden border-0"
                onClick={() => handleSwiperSlideBlogs(blogPostDetails._id)}
              >
                {/* <img
                  src={blogPostDetails.image?.url || "fallback-image-url.jpg"}
                  alt={blogPostDetails.title}
                  className="img-fluid"
                /> */}
                <img
                  src={blogPostDetails?.value?.[2] || "fallback-image-url.jpg"}
                  alt={blogPostDetails.title}
                  className="img-fluid"
                />
                <div className="bg-overlay"></div>
                <div className="card-img-overlay">
                  <Link to={`/bloggrid`} className="text-white">
                    <h5 className="card-title">
                      {blogPostDetails?.value?.[1]}
                    </h5>
                  </Link>
                  <p className="card-text text-white-50">
                    <Link to="/bloggrid" className="text-white-50">
                      {/* {blogPostDetails.createdBy} */}
                      {blogPostDetails?.value?.[5]}
                    </Link>
                    {/* - {blogPostDetails.createdAt.slice(0, 10)} */}-{" "}
                    {blogPostDetails?.value?.[6]?.slice(0, 10)}
                  </p>
                </div>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default BlogPost;

const contactPageMapping = {
  id: "671f4c7859d17c630608a7f9",
  pageTitle: {
    id: "96cd9185-189f-4efe-90c3-0096390b3c95",
  },
  breadcrumb: {
    id: "9823f3f3-4aee-49c4-9de5-9e096180c636",
    breadcrumb1: { id: "869ff7dc-125c-415a-8bdb-0f627a1823d3" },
    breadcrumb2: { id: "c81efc91-f41e-4177-9b7c-61d33a920397" },
    breadcrumb3: { id: "8cb4cd8a-6101-46db-973e-5fe4dbb4ce77" },
  },

  formHeadings: {
    id: "42318295-b1aa-477a-8a4b-cd44d9bcea77",
    heading1: { id: "4f0cf4c8-d7e5-4f93-8155-43a5e50a9bc0" },
    heading2: { id: "88739234-bd02-446e-bcdc-793028be6e63" },
  },

  formFields: {
    id: "7214aa47-6c66-4860-8370-41c9f6d49f4b",
    nameLabel: { id: "0f0fa98c-fae3-4e35-ae77-30a002fdb222" },
    name: { id: "6e6218d7-afd4-4cf0-ab40-fa03a73e3862" },
    emailLabel: { id: "1d67586b-2796-4929-ba05-9761380175e1" },
    email: { id: "87b0e069-a0c4-4585-a47e-10af58666f15" },
    messageLabel: { id: "2d513549-e564-46ae-bb82-35db847a6534" },
    message: { id: "3407618c-c992-40a3-8c53-e443d357f324" },
  },

  buttonText: {
    id: "7c9b246a-8e5f-4478-9f95-32be9d7ff75f",
  },
  image: {
    id: "c497573c-1eaf-454a-afb1-6d8e0e79a767",
  },

  location: {
    id: "54c457b0-524f-40d4-8669-8d9ec5c697a7",
    locationIcon: {
      id: "471b2f04-3ad1-425d-a7a6-c5c79953eb1e",
    },
    address: {
      id: "73b3e72f-b486-4070-ad5c-7ab09ca3dd17",
    },
    emailIcon: {
      id: "755ce920-eadd-4044-a7ac-a56c4b6c9e84",
    },
    emailid: {
      id: "0022067d-58be-429a-97fc-a3e66d74060d",
    },
    phoneIcon: {
      id: "642c7108-7802-48da-a7b0-808a4ae0bb66",
    },
    phoneNo: {
      id: "b754cc2b-7d8b-44d1-b340-ae0cc34e6f85",
    },
  },
};

export default contactPageMapping;

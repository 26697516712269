// import { createStore, combineReducers } from "redux";
// import reducer from "./reducers";

// const rootReducer = combineReducers({
//   pagesDataReducer: reducer,
//   componentDataReducer: reducer,
// });

// const store = createStore(rootReducer);

// export default store;


import { createStore } from "redux";
import rootReducer from "./index";

const store = createStore(rootReducer);

export default store;

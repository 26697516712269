import React, { useState } from "react";
import { Input } from "reactstrap";

const JobSearch = ({ onTitleChange }) => {
  const [jobTitle, setJobTitle] = useState("");

  const handleInputChange = (e) => {
    setJobTitle(e.target.value);
    onTitleChange(e.target.value); // Notify parent of the change
  };

  return (
    <React.Fragment>
      <Input
        type="search"
        id="job-title"
        className="form-control filter-input-box"
        placeholder="Job Title or Description"
        onChange={handleInputChange}
      />
    </React.Fragment>
  );
};

export default JobSearch;

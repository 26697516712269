import React, { useState } from "react";
import { Link } from "react-router-dom";

const Tags = ({ childCategories, onSearch }) => {
  const [selectedTags, setSelectedTags] = useState([]);

  const handleTagClick = (tag) => {
    const newTags = selectedTags.includes(tag)
      ? selectedTags.filter(t => t !== tag)
      : [...selectedTags, tag];
    setSelectedTags(newTags);
    onSearch(newTags);
  };

  return (
    <React.Fragment>
      <div className="mt-4 pt-2">
        <div className="sd-title">
          <h6 className="fs-16 mb-3">Latest Tags</h6>
        </div>
        <div className="tagcloud mt-3 d-flex flex-wrap align-items-start gap-1 ">
          {(childCategories?.length > 4
            ? childCategories
            : childCategories || []
          ).map((tagsDetails, key) => (
            <Link
              className={`badge fs-12 mt-2 ${
                selectedTags.includes(tagsDetails) ? "tag-select" : "tag-cloud"
              }`}
              to="#"
              key={key}
              onClick={() => handleTagClick(tagsDetails)}
            >
              <label>
                <input
                  type="radio"
                  name={tagsDetails}
                  style={{ display: "none" }}
                />
                {tagsDetails}
              </label>
            </Link>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Tags;

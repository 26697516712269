import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import toast from "react-hot-toast";
import magicJson from "../../../magicJson/magicJson";
import userImage from "../../../assets/images/user/img-02.jpg";

const TeamPage = () => {
  const pagesData = useSelector((state) => state.pagesDataReducer.pagesData);

  const [teamData, setTeamData] = useState();
  const [teamCollectionId, setTeamCollectionId] = useState(null);

  const getTeamdata = async () => {
    if (!teamCollectionId) return;

    magicJson.endpoint = "contentManager";
    magicJson.executor = "getCollectionItems";
    magicJson.data = { _id: teamCollectionId };

    const data = new FormData();
    data.append("request", JSON.stringify(magicJson));

    const requestOptions = {
      method: "POST",
      // headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN}` },
      body: data,
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL,
        requestOptions
      );
      const result = await response.json();
      setTeamData(result.data[0]);
    } catch (error) {
      toast.remove();
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    if (pagesData) {
      const teamPage = pagesData.find(
        (page) => page.contentType === "LandingPage" && page.name === "Team"
      );
      if (teamPage) {
        // Assuming _id is under the third item in componentData
        // We need to change the index logic here
        // setTeamCollectionId(
        //   teamPage.componentData?.find(
        //     (item) =>
        //       item.componentName === "Collections" && item.label === "Team"
        //   )?._id
        // );
        setTeamCollectionId(teamPage.componentData?.[2]?._id);
      } else {
        setTeamCollectionId(null);
      }
    }
  }, [pagesData]);

  useEffect(() => {
    getTeamdata();
  }, [teamCollectionId]);

  const handleClick = () => {
    toast.remove();
    toast.error("Work in progress!");
  };

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row>
            {teamData?.length ? (
              teamData.map((teamPageDetails, key) => (
                <Col lg={4} md={6} key={key}>
                  <div className="team-box card border-0 mt-4">
                    <div className="team-img position-relative mx-auto">
                      <img
                        src={userImage}
                        alt="User Profile Img"
                        className="img-thumbnail"
                      />
                      <ul className="team-social list-unstyled">
                        <li>
                          <Link to="#" onClick={handleClick}>
                            <i className="mdi mdi-facebook"></i>
                          </Link>
                        </li>
                        <li className="my-1">
                          <Link to="#" onClick={handleClick}>
                            <i className="mdi mdi-twitter"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#" onClick={handleClick}>
                            <i className="mdi mdi-skype"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="team-content card-body text-center">
                      <Link to="/candidatedetails" className="primary-link">
                        <h6 className="fs-17 mb-1">
                          {teamPageDetails?.componentData?.[0]?.value}
                        </h6>
                      </Link>
                      <p className="text-muted mb-0">
                        {teamPageDetails?.componentData?.[1]?.value}
                      </p>
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <div className="mt-4">No Team Members Found!</div>
            )}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default TeamPage;

import React, { useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Input, Row } from "reactstrap";
import CountryOptions from "../../Home/SubSection/CountryOptions";
import JobType from "../../Home/SubSection/JobType";

const JobSearchOptions = ({
  searchTerm,
  setSearchTerm,
  onCountryChange,
  onJobTypeChange,
  filterJobs, // Pass filterJobs function as a prop
  jobVacancyList,
  selectedCountry,
  selectedJobType,
  setSelectedExperience,
  setSelectedDateRange,
  setSelectedSkills,
  jobTitle,
  country,
}) => {
  const handleResetFilters = () => {
    setSearchTerm("");
    onCountryChange({ label: "All locations", value: "all" });
    onJobTypeChange({ label: "All skills", value: "all" });
    setSelectedExperience([]);
    setSelectedDateRange(null);
    setSelectedSkills([]);
    filterJobs();
  };

  // Handle search input change and trigger filtering
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Update the search term
  };

  // Handle country change and trigger filtering
  const handleCountryChange = (selectedOption) => {
    onCountryChange(selectedOption); // Update the selected country
  };

  // Handle job type change and trigger filtering
  const handleJobTypeChange = (selectedOption) => {
    onJobTypeChange(selectedOption); // Update the selected job type
  };

  useEffect(() => {
    // You can use this to trigger an initial filter when the component mounts if needed
    filterJobs();
  }, [searchTerm, selectedCountry, selectedJobType]);

  return (
    <React.Fragment>
      <div className="job-list-header">
        <Form action="#">
          <Row className="g-2">
            <Col lg={4} md={6}>
              <div className="filler-job-form">
                <i
                  className="uil uil-briefcase-alt"
                  style={{ margin: "-5px 0" }}
                ></i>
                <Input
                  type="search"
                  className="form-control filter-input-box"
                  id="exampleFormControlInput1"
                  placeholder="Job Title or Description"
                  value={jobTitle}
                  onChange={handleSearchChange} // Update search term and trigger filter
                  style={{ marginTop: "-10px" }}
                />
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="filler-job-form">
                <i
                  className="uil uil-location-point"
                  style={{ margin: "-5px 0" }}
                ></i>
                <CountryOptions
                  onCountryChange={handleCountryChange}
                  selectedCountry={selectedCountry}
                  country={country}
                />
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="filler-job-form">
                <i
                  className="uil uil-clipboard-notes"
                  style={{ margin: "-5px 0" }}
                ></i>
                <JobType
                  jobVacancyList={jobVacancyList}
                  onJobTypeChange={handleJobTypeChange}
                  selectedJobType={selectedJobType}
                />
              </div>
            </Col>
            <Col lg={2} md={6}>
              <Link
                to="#"
                className="btn btn-primary w-100"
                onClick={handleResetFilters} // Trigger filtering on button click (optional)
              >
                <i className="uil uil-refresh"></i> Reset All
              </Link>
            </Col>
            {/* <Col lg={3} md={6}>
              <Link
                to="#"
                className="btn btn-primary w-100"
                onClick={filterJobs} // Trigger filtering on button click (optional)
              >
                <i className="uil uil-filter"></i> Filter
              </Link>
            </Col> */}
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default JobSearchOptions;

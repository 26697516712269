import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Images
import AboutImage from "../../../assets/images/about/img-01.jpg";
import toast from "react-hot-toast";

const About = ({aboutSectionData}) => {
  const handleClick = () => {
    toast.remove();
    toast.error("Work in progress!");
  };

  return (
    <React.Fragment>
      <section className="section overflow-hidden">
        <Container>
          <Row className="align-items-center g-0">
            <Col lg={6}>
              <div className="section-title me-lg-5">
                <h6 className="sub-title">{aboutSectionData.aboutMain}</h6>
                <h2 className="title mb-4">{aboutSectionData.title1}</h2>
                <p
                  className="text-muted"
                  dangerouslySetInnerHTML={{
                    __html: aboutSectionData.description,
                  }}
                />

                <Row mt={4} pt={2}>
                  <Col md={6}>
                    <ul className="list-unstyled about-list text-muted mb-0 mb-md-3">
                      <li> Digital Marketing Solutions</li>
                      <li> Our Talented & Experienced Marketing Agency</li>
                      <li> Creative Design</li>
                      <li> New jobs Innovation</li>
                    </ul>
                  </Col>
                  <Col md={6}>
                    <ul className="list-unstyled about-list text-muted">
                      <li> Create Resume</li>
                      <li> 5000+ Comnpanies</li>
                      <li> Our Blog</li>
                      <li> and more...</li>
                    </ul>
                  </Col>
                </Row>
                <div className="mt-3">
                  <Link
                    to="#"
                    className="btn btn-primary btn-hover"
                    onClick={handleClick}
                  >
                    {aboutSectionData.link?.linkLabel}
                    <i className="uil uil-angle-right-b align-middle"></i>
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="about-img mt-4 mt-lg-0">
                <img
                  src={aboutSectionData.imageSrc[0]}
                  alt="image"
                  className="img-fluid rounded"
                  width={520}                  
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default About;

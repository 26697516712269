import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Col, Container, Row } from "reactstrap";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import contactImage from "../../assets/images/contact.png";
import contactPageMapping from "../../mappings/contactPageMapping.js";
import ApiRequest from "../../utils/apiRequest.js";

const apiRequest = new ApiRequest();

const ContactContent = () => {
  const pagesData = useSelector((state) => state.pagesDataReducer.pagesData);

  const { id, formHeadings, formFields, buttonText } = contactPageMapping;

  const [loading, setLoading] = useState(true);
  const [contactPageData, setContactPageData] = useState({});
  const [formHeadingsData, setFormHeadingsData] = useState({
    heading1: "",
    heading2: "",
  });
  const [formFieldsData, setFormFieldsData] = useState({
    nameLabel: "",
    name: "",
    namePlaceholder: "",
    emailLabel: "",
    email: "",
    emailPlaceholder: "",
    messageLabel: "",
    message: "",
    messagePlaceholder: "",
  });
  const [buttonData, setButtonData] = useState({ buttonText: "" });
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    const contactData = pagesData.find((item) => item._id === id);
    if (contactData) setContactPageData(contactData);
  }, [pagesData]);

  useEffect(() => {
    if (contactPageData.componentData) {
      // Form Headings Data
      const formHeadingsData = contactPageData.componentData.find(
        (item) => item.id === formHeadings.id
      );

      if (formHeadingsData) {
        const heading1Data = formHeadingsData.childComponents?.find(
          (item) => item.id === formHeadings.heading1.id
        );

        const heading2Data = formHeadingsData.childComponents?.find(
          (item) => item.id === formHeadings.heading2.id
        );

        setFormHeadingsData({
          heading1: heading1Data?.value || "",
          heading2: heading2Data?.value || "",
        });
      }

      // Form Fields Data
      const formFieldsData = contactPageData.componentData.find(
        (item) => item.id === formFields.id
      );

      if (formFieldsData) {
        const nameLabelData = formFieldsData.childComponents?.find(
          (item) => item.id === formFields.nameLabel.id
        );
        const nameData = formFieldsData.childComponents?.find(
          (item) => item.id === formFields.name.id
        );
        console.log("nameData", nameData);

        const emailLabelData = formFieldsData.childComponents?.find(
          (item) => item.id === formFields.emailLabel.id
        );
        const emailData = formFieldsData.childComponents?.find(
          (item) => item.id === formFields.email.id
        );

        const messageLabelData = formFieldsData.childComponents?.find(
          (item) => item.id === formFields.messageLabel.id
        );
        const messageData = formFieldsData.childComponents?.find(
          (item) => item.id === formFields.message.id
        );

        setFormFieldsData({
          nameLabel: nameLabelData?.value || "",
          name: nameData?.defaultValue || "",
          namePlaceholder: nameData?.placeholder || "",
          emailLabel: emailLabelData?.value || nameLabelData?.value || "",
          email: emailData?.defaultValue || "",
          emailPlaceholder: emailData?.placeholder || "",
          messageLabel: messageLabelData?.value || nameLabelData?.value || "",
          message: messageData?.defaultValue || "",
          messagePlaceholder: messageData?.placeholder || "",
        });
      }

      // Button Data
      const buttonData = contactPageData.componentData.find(
        (item) => item.id === buttonText.id
      );

      if (buttonData) {
        setButtonData({
          buttonText: buttonData.value || "",
        });
      }

      setLoading(false);
    }
  }, [contactPageData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormFieldsData((prev) => ({ ...prev, [name]: value }));

    if (name === "email") {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      setEmailError(emailRegex.test(value) ? "" : "Invalid email address");
    }
  };

  const handleSubmit = async () => {
    const { name, email, message } = formFieldsData;
    if (!name || !email || !message) {
      toast.error("All fields are required!");
      return;
    }
    if (emailError) {
      toast.error("Invalid email");
      return;
    }

    try {
      const requestData = {
        endpoint: "forms",
        executor: "createContactUsResponses",
        data: [{ name, email, comments: message }],
      };

      const result = await apiRequest.sendRequest(requestData);
      if (result) {
        setFormFieldsData((prevFormFieldsData) => ({
          ...prevFormFieldsData,
          name: "",
          email: "",
          message: "",
        }));

        toast.success("Form Submitted Successfully");
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  if (loading) {
    return (
      <div className="loader">
        <div className="spinner"></div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="align-items-center mt-5">
            <Col lg={6}>
              <div className="section-title mt-4 mt-lg-0">
                {console.log(formHeadingsData)}
                <h3 className="title">{formHeadingsData.heading1}</h3>
                <p className="text-muted">{formHeadingsData.heading2}</p>
                <Form method="post" className="contact-form mt-4" id="myForm">
                  <Row>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Form.Label>{formFieldsData?.nameLabel}</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={formFieldsData?.name}
                          onChange={handleChange}
                          placeholder={formFieldsData?.namePlaceholder}
                          required
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Form.Label>{formFieldsData?.emailLabel}</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={formFieldsData?.email}
                          onChange={handleChange}
                          placeholder={formFieldsData?.emailPlaceholder}
                          required
                        />
                        {emailError && (
                          <span className="text-danger">{emailError}</span>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Form.Label>{formFieldsData?.messageLabel}</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="message"
                          value={formFieldsData?.message}
                          onChange={handleChange}
                          rows="4"
                          placeholder={formFieldsData?.messagePlaceholder}
                          required
                        />
                      </div>
                    </Col>
                  </Row>
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="btn btn-primary"
                  >
                    {buttonData.buttonText}
                  </button>
                </Form>
              </div>
            </Col>
            <Col lg={5} className="ms-auto order-first order-lg-last">
              <div className="text-center">
                <img src={contactImage} alt="" className="img-fluid" />
              </div>
              <div className="mt-4 pt-3">
                <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-map-marker"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">
                      2453 Clinton Street, Little Rock, California, USA
                    </p>
                  </div>
                </div>
                <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-envelope"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">contactus@Jobcy.com</p>
                  </div>
                </div>
                <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-phone-alt"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">(+245) 223 1245</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="map">
        <iframe
          title="maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6509157.364974411!2d-123.79641389801948!3d37.193115265681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb9fe5f285e3d%3A0x8b5109a227086f55!2sCalifornia%2C%20USA!5e0!3m2!1sen!2sin!4v1628684675253!5m2!1sen!2sin"
          height="350"
          style={{ border: `0`, width: `100%` }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </React.Fragment>
  );
};

export default ContactContent;

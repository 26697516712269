// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jobDescIcon
{
    min-height: 45.99px;
    min-width: 45.99px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Jobs/JobDetails/rightSideContent.css"],"names":[],"mappings":"AAAA;;IAEI,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".jobDescIcon\n{\n    min-height: 45.99px;\n    min-width: 45.99px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

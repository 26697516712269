import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { Button, Col, Collapse, Input, Label } from "reactstrap";
import { useSelector } from "react-redux";

const Sidebar = ({
  jobVacancyList,
  onExperienceChange,
  selectedExperience,
  selectedSkills,
  setSelectedSkills,
  handleSkillChange,
  handleDateRangeChange,
  selectedDateRange,
  skillsOnCurrentPage,
}) => {
  const [toggleFirst, setToggleFirst] = useState(true);
  const [toggleSecond, setToggleSecond] = useState(true);
  const [toggleThird, setToggleThird] = useState(true);
  const [toggleFourth, setToggleFourth] = useState(true);
  const [toggleFifth, setToggleFifth] = useState(true);
  const [value, setValue] = React.useState(50);
  const pagesData = useSelector((state) => state.pagesDataReducer.pagesData);
  const [jobsPageData, setJobPageData] = useState({});
  const [workExperience, setWorkExperience] = useState([]);
  const [workExperienceObj, setWorkExperienceObj] = useState([]);
  const [datePosted, setDatePosted] = useState([]);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    for (let item of pagesData) {
      if (item.name === "Jobs" && item.contentType === "LandingPage")
        setJobPageData(item);
    }
  }, [pagesData]);

  useEffect(() => {
    if (jobsPageData.componentData) {
      for (let item of jobsPageData.componentData) {
        if (item.label === "Work Experience") setWorkExperience(item.value);
      }
    }
  }, [jobsPageData]);

  useEffect(() => {
    let arr = [];
    for (let item of workExperience) {
      arr.push({ value: toKebabCase(item), label: item });
    }
    setWorkExperienceObj(arr);
  }, [workExperience]);

  useEffect(() => {
    if (jobsPageData.componentData) {
      for (let item of jobsPageData.componentData) {
        if (item.label === "Date Posted Filter") setDatePosted(item.value);
      }
    }
  }, [jobsPageData]);

  useEffect(() => {
    if (jobsPageData.componentData) {
      for (let item of jobsPageData.componentData) {
        if (item.label === "Tags") setSkills(item.value);
      }
    }
  }, [jobsPageData]);

  const [isChecked, setIsChecked] = useState(true);
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  const [isDateChecked, setIsDateChecked] = useState(true);
  const handleDateOnChange = () => {
    setIsDateChecked(!isDateChecked);
  };

  const handleExperienceChange = (e) => {
    onExperienceChange(e.target.value);
  };

  const handleClick = () => {
    toast.remove();
    toast.error("Work in progress!");
  };

  const Experienceoptions = [
    { value: "no-experience", label: "No Experience" },
    { value: "0-3-years", label: "0-3 Years" },
    { value: "3-6-years", label: "3-6 Years" },
    { value: "more-than-6-years", label: "More than 6 Years" },
  ];

  const skillSet = new Set();
  jobVacancyList?.forEach((job) => {
    job.skills.forEach((skill) => {
      skillSet.add(skill);
    });
  });

  function toKebabCase(str) {
    return str
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]/g, "");
  }

  const skillArray = Array.from(skillSet);

  if (skillsOnCurrentPage.length < 10) {
    let len = 10 - skillsOnCurrentPage.length;
    // Append more skills if available
    const additionalSkills = skillArray.filter(
      (skill) => !skillsOnCurrentPage.includes(skill)
    );
    const skillsToAdd = additionalSkills.slice(0, len);
    skillsOnCurrentPage.push(...skillsToAdd);
  }

  return (
    <React.Fragment>
      <Col lg={3}>
        <div className="side-bar mt-5 mt-lg-0">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item mt-4">
              <h2 className="accordion-header" id="experienceOne">
                <Button
                  className={`accordion-button ${
                    !toggleSecond ? "collapsed" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setToggleSecond(!toggleSecond);
                  }}
                  role="button"
                >
                  Work Experience
                </Button>
              </h2>
              <Collapse isOpen={toggleSecond}>
                <div className="accordion-body">
                  <div className="side-title">
                    {workExperienceObj.map((option) => (
                      <div key={option.value} className="form-check mt-2">
                        <input
                          type="radio"
                          className="form-check-input border-color"
                          id={option.value}
                          name="experience"
                          value={option.value}
                          checked={selectedExperience === option.value}
                          onChange={() => onExperienceChange(option.value)}
                          onClick={() => {
                            if (selectedExperience === option.value) {
                              onExperienceChange(""); // Deselect by setting it to an empty string
                            } else {
                              onExperienceChange(option.value); // Select the current option
                            }
                          }}
                        />
                        <label
                          className="form-check-label ms-2 text-muted"
                          htmlFor={option.value}
                        >
                          {option.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </Collapse>
            </div>

            <div className="accordion-item mt-3">
              <h2 className="accordion-header" id="datePosted">
                <Button
                  className={`accordion-button ${
                    !toggleFourth ? "collapsed" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setToggleFourth(!toggleFourth);
                  }}
                  role="button"
                >
                  Date Posted
                </Button>
              </h2>
              <Collapse isOpen={toggleFourth}>
                <div className="accordion-body">
                  <div className="side-title form-check-all">
                    <div className="form-check">
                      {datePosted &&
                        datePosted.map((item, index) => (
                          <div key={index}>
                            <Input
                              className="form-check-input border-color"
                              name="datePosted"
                              type="radio"
                              id={`check-${toKebabCase(item)}`}
                              value={toKebabCase(item)}
                              onChange={() =>
                                handleDateRangeChange({
                                  value: toKebabCase(item),
                                })
                              }
                              checked={
                                selectedDateRange?.value === toKebabCase(item)
                              }
                              onClick={() => {
                                if (selectedDateRange === toKebabCase(item)) {
                                  handleDateRangeChange(null); // Deselect by setting it to null
                                } else {
                                  handleDateRangeChange(item); // Select the current option
                                }
                              }}
                            />
                            <Label
                              className="form-check-label ms-2 text-muted"
                              htmlFor={`check-${toKebabCase(item)}`}
                            >
                              {item}
                            </Label>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>

            <div className="accordion-item mt-3">
              <h2 className="accordion-header" id="tagCloud">
                <Button
                  className={`accordion-button ${
                    !toggleFifth ? "collapsed" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setToggleFifth(!toggleFifth);
                  }}
                  role="button"
                >
                  Tags
                </Button>
              </h2>
              <Collapse isOpen={toggleFifth}>
                <div className="accordion-body">
                  <div className="side-title">
                    {/* Iterate through skillsOnCurrentPage to display current page skills */}
                    {skills &&
                      skills.map((skill, index) => (
                        <Button
                          to="#"
                          className={`badge margin-right ${
                            selectedSkills.includes(skill)
                              ? "tag-select"
                              : "tag-cloud"
                          } fs-13 mt-2`}
                          key={index}
                          onClick={() => handleSkillChange(skill)}
                        >
                          {skill}
                        </Button>
                      ))}
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default Sidebar;

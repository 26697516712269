import React, { useContext, useState, useEffect } from "react";
import { Col, Row, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import toast from "react-hot-toast";
import { getcomponents } from "../Alldata";
import { UserContext } from "../Alldata";
import homePageMapping from "../../mappings/homePageMapping";
import { useSelector } from "react-redux";

const Jobcatogaries = () => {
  const pagesData = useSelector((state) => state.pagesDataReducer.pagesData);

  // 1. for components
  const [components, setComponents] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);


  let categoriesfinal = [];

  if (Array.isArray(components[0])) {
    categoriesfinal = components[0].map((item) => item.componentData[0]?.childComponents)
  }

  // 2. for title and description
  const { filteredrecentData, name } = useContext(UserContext);

  const myObject =
    Array.isArray(filteredrecentData) &&
    filteredrecentData.find((item) => item._id === homePageMapping.id)
      ?.componentData;

  const title =
    Array.isArray(myObject) &&
    myObject
      .find((item) => item._id === homePageMapping.BrowseJobsCategories1.id)
      ?.childComponents.find(
        (item) => item.id === homePageMapping.BrowseJobsCategories1.title.id
      )?.value;

  const description =
    Array.isArray(myObject) &&
    myObject
      .find((item) => item._id === homePageMapping.BrowseJobsCategories1.id)
      ?.childComponents.find(
        (item) =>
          item.id === homePageMapping.BrowseJobsCategories1.description.id
      )?.value;

  const idtosent =
    Array.isArray(filteredrecentData) &&
    filteredrecentData.find(
      (item) => item._id === homePageMapping.BrowseJobsCategories.id
    )?._id;

  useEffect(() => {
    const token = localStorage.getItem("token");
    getcomponents(token, idtosent).then((data) => setComponents(data));
  }, [filteredrecentData]);

  useEffect(() => {
    if (components[0]?.length > 0) {
      const data = components[0].map((item) => {
        const name = item.componentData[0].childComponents.find((it) => it.id === homePageMapping.BrowseJobsCategories.BrowseJobsCategories2.name.id).value;

        const count = item.componentData[0].childComponents.find((it) => it.id === homePageMapping.BrowseJobsCategories.BrowseJobsCategories2.job.id).value;

        const icon = item.componentData[0].childComponents.find((it) => it.id === homePageMapping.BrowseJobsCategories.BrowseJobsCategories2.Icon.id).files[0].url;

        const label = item.componentData[0].childComponents.find((it) => it.id === homePageMapping.BrowseJobsCategories.BrowseJobsCategories2.jobs.id).value;

        return {name,count,icon,label};
      });

      console.log(data);

      setCategoriesData(data);
    }
  }, [components])

  // const categories = [
  //   {
  //     id: 1,
  //     icon: "uim-layers-alt",
  //     name: "IT & Software",
  //     job: 2024
  //   },
  //   {
  //     id: 2,
  //     icon: "uim-airplay",
  //     name: "Technology",
  //     job: 1250
  //   },
  //   {
  //     id: 3,
  //     icon: "uim-bag",
  //     name: "Government",
  //     job: 802
  //   },
  //   {
  //     id: 4,
  //     icon: "uim-user-md",
  //     name: "Accounting / Finance",
  //     job: 577
  //   },
  //   {
  //     id: 5,
  //     icon: "uim-hospital",
  //     name: "Construction / Facilities",
  //     job: 285
  //   },
  //   {
  //     id: 6,
  //     icon: "uim-telegram-alt",
  //     name: "Tele-communications",
  //     job: 495
  //   },
  //   {
  //     id: 7,
  //     icon: "uim-scenery",
  //     name: "Design & Multimedia",
  //     job: 1045
  //   },
  //   {
  //     id: 8,
  //     icon: "uim-android-alt",
  //     name: "Human Resource",
  //     job: 1516
  //   }
  // ];

  const handleClick = () => {
    toast.remove();
    toast.error("Work in progress!");
  };

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="section-title text-center">
                {/* <h3 className="title">Browse Jobs Categories </h3> */}
                <h3 className="title">{title}</h3>
                <p className="text-muted">
                  {/* Post a job to tell us about your project. We'll quickly match
                  you with the right freelancers. */}
                  {description}
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            {/* {(categories || []).map((item, key) => ( */}
            {(categoriesData || []).map((item, key) => (
              <Col lg={3} md={6} mt={4} pt={2} key={key}>
                <div className="popu-category-box rounded text-center">
                  <div className="popu-category-icon icons-md">
                    <img src={item.icon} className="text-primary iconify-img" />
                  </div>
                  <div className="popu-category-content mt-4">
                    <Link
                      to="#"
                      className="text-dark stretched-link"
                      onClick={handleClick}
                    >
                      <h5 className="fs-18">{item.name}</h5>
                    </Link>
                    <p className="text-muted mb-0">{item.count} {item.label}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Row>
            <Col lg={12}>
              <div className="mt-5 text-center">
                <Link
                  to="#"
                  className="btn btn-primary btn-hover"
                  onClick={handleClick}
                >
                  Browse All Categories <i className="uil uil-arrow-right"></i>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Jobcatogaries;

import React from "react";
import Section from "../Home2/Section";

const Contact = () => {
  document.title = "Contact | Jobcy";
  return (
    <React.Fragment>
      <Section />
    </React.Fragment>
  );
};

export default Contact;

const jobsPageMappping = {
    "id": "6718a55893b5f244673f47ec",
    "pageTitle": {
        "id": "9e6db40e-e2e3-45b3-aa7d-a44301bf59e9"
    },
    "datePostedFilter": {
        "id": "d1c66ba3-9eaa-45fe-aff3-bde92ea06f19"
    },
    "workExperience": {
        "id": "c23f3cb0-17ea-48b2-8678-0354a6bbc0fa"
    },
    "tags": {
        "id": "c9023fb9-bed1-4ba6-9bc3-c5a59dff9a9b"
    },
    "locationDropdown": {
        "id": "fba45b87-4fac-4e41-a3cb-9455cbcb827f"
    },
    "skillsDropdown": {
        "id": "01409263-12ca-446a-bef2-324e718e5a5c"
    },
    "breadcrumb": {
        "id": "f0df9dee-f2cf-4dee-8b73-25a8100fe660",
        "breadcrumb1": {
            "id": "869ff7dc-125c-415a-8bdb-0f627a1823d3"
        },
        "breadcrumb2": {
            "id": "c81efc91-f41e-4177-9b7c-61d33a920397"
        },
        "breadcrumb3": {
            "id": "8cb4cd8a-6101-46db-973e-5fe4dbb4ce77"
        }
    },
    "footer": {
        "id": "eb5259a3-8b0a-42b0-bedb-ff154f7f6f0a",
        "sec1": {
            "id": "21eff39a-b5fc-4a0d-a8a2-fa3d4d5f0608",
            "title": {
                "id": "f8c062ad-1530-4e3d-a957-94aabaf66009"
            },
            "subtitle": {
                "id": "46a901cf-c0c4-4c65-8003-9cc0b41103b7"
            },
            "enterEmail": {
                "id": "6594dd3b-b95a-4691-af53-f0123bc9d1f8"
            }
        },
        "jobcy": {
            "id": "e4b7b339-0c36-45b2-bfb9-d8f6b2bb925e",
            "title": {
                "id": "c81ef367-a5c4-4393-9d25-c34b6fe9539c"
            },
            "description": {
                "id": "2b66f6fc-3d85-4234-8867-2755bfbbbc8f"
            },
            "followUsOn:": {
                "id": "edf12cd4-8c81-4bba-b6e6-76c82898949b"
            },
            "link1": {
                "id": "9f40373e-6fc4-4d80-a783-6eb5a4b538f8"
            },
            "link2": {
                "id": "ac085cba-80f8-40f0-b1b2-b5f43be7e122"
            },
            "link3": {
                "id": "5b93401a-e58f-4dc5-ba9b-7d6b721c1914"
            },
            "link4": {
                "id": "953c178d-8bd4-4086-ad52-cf8ab1a0f8c0"
            }
        },
        "company": {
            "id": "f3ca600f-7f2e-4f08-96b0-48f38317e921",
            "aboutUs": {
                "id": "3a035014-2ec4-46a1-b735-f80e28076b7c"
            },
            "contactUs": {
                "id": "aa49cae3-60ad-447b-ba32-05a187494a9d"
            },
            "services": {
                "id": "2e949686-7d0f-4169-8832-28dc770381b4"
            },
            "blog": {
                "id": "145606d7-de64-488e-bb91-ca54c2fd43c6"
            },
            "team": {
                "id": "7de6759f-0d15-4481-a128-00e5cc35e498"
            },
            "pricing": {
                "id": "0f067212-23ad-4c78-ba04-2227583294e9"
            }
        },
        "forJobs": {
            "id": "e9e4ff8a-9214-47a0-b171-bb233c490e45",
            "browseCategories": {
                "id": "69f6aa78-1a71-48ef-81d7-829430a49196"
            },
            "browseJobs": {
                "id": "8d34bf07-3a98-4057-9913-8b49474666fb"
            },
            "jobDetails": {
                "id": "9470178f-c2f9-4d37-a86a-e9c17d97abf9"
            },
            "bookmarkJobs": {
                "id": "27c52666-e454-4213-bcdc-65ea6f91dcd5"
            }
        },
        "forCandidates": {
            "id": "ec951f87-13f2-4ebd-b93c-b472416e26ab",
            "candidateList": {
                "id": "04e435db-247a-443b-bd8d-e06210b2ad4b"
            },
            "candidateGrid": {
                "id": "fc635fad-2c57-4ebb-8813-1b19ba93801b"
            },
            "candidateDetails": {
                "id": "25a117cd-ee64-4c95-90cf-a01c293efa51"
            }
        },
        "support": {
            "id": "278645ea-b115-4f97-8123-6e84509937fe",
            "helpCenter": {
                "id": "4da32ccb-26c8-4c93-932c-18217d72db37"
            },
            "faq'S": {
                "id": "4de4d243-aa5b-42c8-9bcf-0b66245aad17"
            },
            "privacyPolicy": {
                "id": "e873d620-4d6e-4f59-a807-1f61963b13be"
            }
        },
        "2024©Jobcy": {
            "id": "38c0fee7-0b4c-4a0c-88f0-5a1f9f81bc9a"
        }
    }
}

export default jobsPageMappping
import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col, Card, CardImg, CardBody } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { UserContext } from "../Alldata";
import { getcomponents } from "../Alldata";
import homePageMapping from "../../mappings/homePageMapping";

import "swiper/css";
import "swiper/css/virtual";
import "swiper/css/pagination";
import "swiper/css/autoplay";

const Testimonal = () => {
  const { filteredrecentData, name } = useContext(UserContext);
  const [components, setComponents] = useState([]);

  const myObject =
    Array.isArray(filteredrecentData) &&
    filteredrecentData.find((item) => item._id === homePageMapping.id)
      ?.componentData;

  const title =
    Array.isArray(myObject) &&
    myObject
      .find((item) => item._id === homePageMapping.HappyCandidates1.id)
      ?.childComponents.find(
        (item) => item.id === homePageMapping.HappyCandidates1.title.id
      )?.value;

  const description =
    Array.isArray(myObject) &&
    myObject
      .find((item) => item._id === homePageMapping.HappyCandidates1.id)
      ?.childComponents.find(
        (item) => item.id === homePageMapping.HappyCandidates1.description.id
      )?.value;

  const idtosent =
    Array.isArray(filteredrecentData) &&
    filteredrecentData.find(
      (item) => item._id === homePageMapping.HappyCandidates.id
    )?._id;

  let categoriesfinal = [];

  if (Array.isArray(components?.[0])) {
    categoriesfinal = components[0].map(
      (item) => item.componentData[0]?.childComponents
    );
  }

  useEffect(() => {
    // const token = localStorage.getItem("token");
    const token = process.env.REACT_APP_TOKEN;
    getcomponents(token, idtosent).then(setComponents);
  }, [filteredrecentData]);

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="section-title text-center mb-4 pb-2">
                {/* <h3 className="title mb-3">Happy Candidates</h3> */}
                <h3 className="title mb-3">{title}</h3>
                <p className="text-muted">
                  {/* Post a job to tell us about your project. We'll quickly match
                  you with the right freelancers. */}
                  {description}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Swiper
                className="pb-5"
                loop={true}
                modules={[Autoplay, Pagination]}
                slidesPerView={1}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                pagination={{ clickable: true }}
              >
                <div className="swiper-wrapper">
                  {categoriesfinal.slice(0, 3).map((innerArray, outerKey) => (
                    <SwiperSlide key={outerKey}>
                      <Card className="testi-box">
                        <CardBody>
                          <div className="mb-4">
                            {/* <CardImg
                              src={innerArray[0]?.childComponents[0].label}
                              height="50"
                              alt={innerArray[1]?.value}
                            /> */}
                          </div>
                          <p className="testi-content lead text-muted mb-4">
                            {innerArray?.[1].value}
                          </p>
                          <h5 className="mb-0"> {innerArray?.[2]?.value} </h5>
                          <p className="text-muted mb-0">
                            {innerArray?.[3].value}
                          </p>
                        </CardBody>
                      </Card>
                    </SwiperSlide>
                  ))}
                </div>
                <div className="swiper-pagination"></div>
              </Swiper>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Testimonal;

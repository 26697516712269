import React, { useState, useRef } from "react";
import {
  Modal,
  ModalBody,
  Input,
  Label,
  Form,
  FormGroup,
  FormFeedback,
  Button,
} from "reactstrap";
import toast from "react-hot-toast";
import magicJson from "../../../magicJson/magicJson";

const ApplyNowForm = ({ show, updateModalStateByChild, jobId }) => {
  const [modal, setModal] = useState(true);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    resume: null,
  });

  // Refs for focusing on invalid fields
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const resumeRef = useRef(null);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    const newValue = type === "file" ? files[0] : value;

    setFormData({
      ...formData,
      [name]: newValue,
    });

    validateForm(name, newValue);
  };

  const validateForm = (name, value) => {
    const newErrors = { ...errors };
    switch (name) {
      case "name":
        // Name field should only contain letters and spaces
        const nameRegex = /^[a-zA-Z\s]+$/;
        if (!value) {
          newErrors.name = "Name is required";
        } else if (!nameRegex.test(value)) {
          newErrors.name = "Name must only contain letters and spaces";
        } else {
          newErrors.name = "";
        }
        break;
      case "email":
        const emailRegex =
          /^(?!.*(\.\.|\.\-|\-\.|--|_\-|\.\_|\-\.|\.\+|\+\.|--|\+\+|__|%%|\+\%|\%\.|\%\-|\-\%))([a-zA-Z0-9]+(?:[._%+-][a-zA0-9]+)*)@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (value === "") {
          newErrors.email = "Email is required";
        } else if (!emailRegex.test(value)) {
          newErrors.email = "Invalid Email Address"; // Clear the error for email in errors object
        } else {
          newErrors.email = ""; // No error for email
        }
        break;
      case "resume":
        newErrors.resume = value ? "" : "Resume is required";
        break;
      default:
        break;
    }

    setErrors((prev) => ({ ...prev, ...newErrors }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setSubmitted(true); // Set submitted state to true for validation to trigger

    // First, validate all fields
    validateForm("name", formData.name);
    validateForm("email", formData.email);
    validateForm("resume", formData.resume);

    const isValid =
      Object.values(errors).every((error) => !error) &&
      formData.name &&
      formData.email &&
      formData.resume;

    if (isValid) {
      const data = new FormData();

      try {
        magicJson.endpoint = "apply";
        magicJson.executor = "createCandidate";
        magicJson.data = [
          {
            jobId,
            name: formData.name,
            email: formData.email,
            message: formData.message,
          },
        ];

        data.append("request", JSON.stringify(magicJson));
        data.append("files", formData.resume);
        const requestOptions = {
          method: "POST",
          // headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN}` },
          body: data,
        };

        fetch(process.env.REACT_APP_API_URL, requestOptions).then((resp) => {
          resp.json().then(() => {
            setFormData({ name: "", email: "", message: "", resume: null });
            openModal();
            setIsSubmitting(false);
            toast.remove();
            toast.success("Applied Successfully!");
          });
        });
      } catch (error) {
        console.log("error", error.message);
      }
    } else {
      setIsSubmitting(false);

      // Focus on the first invalid field
      if (errors.name) {
        nameRef.current.focus();
      } else if (errors.email) {
        emailRef.current.focus();
      } else if (errors.resume) {
        resumeRef.current.focus();
      }
    }
  };

  const openModal = () => {
    setModal(!modal);
    setErrors({});
    setSubmitted(false);
    updateModalStateByChild(false);
  };

  if (!show) {
    return null;
  }

  return (
    <React.Fragment>
      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modal} toggle={openModal} centered>
            <ModalBody className="modal-body p-5">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Apply For This Job
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  disabled={isSubmitting}
                ></button>
              </div>
              <Form noValidate onSubmit={handleSubmit}>
                <FormGroup className="mb-3">
                  <Label for="nameControlInput" className="form-label">
                    Name *
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    value={formData.name}
                    className="form-control"
                    id="nameControlInput"
                    placeholder="Enter your name"
                    onChange={handleChange}
                    invalid={submitted && !!errors.name} // Apply invalid styling
                    ref={nameRef}
                    disabled={isSubmitting}
                  />
                  <FormFeedback style={{ color: "red" }}>
                    {errors.name}
                  </FormFeedback>
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label for="emailControlInput2" className="form-label">
                    Email Address *
                  </Label>
                  <Input
                    type="text"
                    name="email"
                    value={formData.email}
                    className="form-control"
                    id="emailControlInput2"
                    placeholder="Enter your email"
                    onChange={handleChange}
                    invalid={submitted && !!errors.email} // Apply invalid styling
                    ref={emailRef}
                    disabled={isSubmitting}
                  />
                  <FormFeedback style={{ color: "red" }}>
                    {errors.email}
                  </FormFeedback>
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label for="messageControlTextarea" className="form-label">
                    Message
                  </Label>
                  <textarea
                    className="form-control"
                    name="message"
                    value={formData.message}
                    id="messageControlTextarea"
                    rows="4"
                    placeholder="Enter your message"
                    onChange={handleChange}
                    disabled={isSubmitting}
                  ></textarea>
                </FormGroup>
                <FormGroup className="mb-4">
                  <Label className="form-label" for="inputGroupFile01">
                    Upload Resume *
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="inputGroupFile01"
                    name="resume"
                    onChange={handleChange}
                    invalid={submitted && !!errors.resume} // Apply invalid styling
                    ref={resumeRef}
                    disabled={isSubmitting}
                  />
                  <FormFeedback style={{ color: "red" }}>
                    {errors.resume}
                  </FormFeedback>
                </FormGroup>
                <Button
                  type="submit"
                  color="primary"
                  className="w-100"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Sending Application" : "Send Application"}
                </Button>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ApplyNowForm;

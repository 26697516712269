export const PAGES_DATA = "PAGES_DATA";
export const COMPONENT_DATA = "COMPONENT_DATA";

export const setPagesData = (links) => ({
  type: PAGES_DATA,
  payload: links,
});

export const setComponentData = (componentData) => ({
  type: COMPONENT_DATA,
  payload: componentData,
});
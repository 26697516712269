import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Routes from "./Routes/index";
import login from "./authService/authService";
import { setPagesData } from "./redux/actions";
import magicJson from "./magicJson/magicJson";
import "./assets/scss/themes.scss";

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [token, setToken] = useState(localStorage.getItem("token"));

  const fetchToken = async () => {
    try {
      const newToken = await login();
      if (newToken) {
        localStorage.setItem("token", newToken);
        setToken(newToken);
      }
    } catch (error) {
      console.error("Something Went Wrong!");
    }
  };

  const fetchPagesData = async () => {
    magicJson.endpoint = "contentTypeBuilder";
    magicJson.executor = "getPages";
    magicJson.metadata.data.options.sortBy = { sort: { _id: 1 } };

    const data = new FormData();
    data.append("request", JSON.stringify(magicJson));

    const requestOptions = {
      method: "POST",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      body: data,
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL,
        requestOptions
      );
      const result = await response.json();

      if (response.status !== 401) {
        dispatch(setPagesData(result.data[0]));
      }
    } catch (error) {
      console.error("Something Went Wrong!");
    }
  };

  useEffect(() => {
    if (!token) {
      fetchToken();
    }

    fetchPagesData();
  }, [token, location.pathname]);

  if (!token) {
    return <></>;
  }

  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
};

export default App;

import { createContext, useState } from "react";
import magicJson from "../magicJson/magicJson";
import ApiRequest from "../utils/apiRequest";

const apiRequest = new ApiRequest();
const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [resourcesList, setResourcesList] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [searchResults, setSearchResults] = useState(resourcesList);
  const [popularPosts, setPopularPosts] = useState(resourcesList);
  const [childCategories, setChildCategories] = useState([]);

  // Fetch all blogs
  const fetchBlogs = async () => {
    magicJson.endpoint = "resources";
    magicJson.executor = "getResources";

    const data = await apiRequest.sendRequest(magicJson);
    setResourcesList(data?.data[0] || []);
    setSearchResults(data?.data[0] || []);

    const allCategories =
      Array.isArray(data?.data[0]) &&
      data?.data[0].flatMap((item) => item.parentCategory);
    const uniqueCategories = allCategories && [...new Set(allCategories)];
    setParentCategories(uniqueCategories);

    const allChildCategories =
      Array.isArray(data?.data[0]) &&
      data?.data[0].flatMap((item) => item.category);
    const uniqueChildCategories = allChildCategories && [
      ...new Set(allChildCategories),
    ];
    setChildCategories(uniqueChildCategories);
  };

  const sortByViews = () => {
    const sortedData = [...resourcesList].sort(
      (a, b) => (b.views || 0) - (a.views || 0)
    );
    setPopularPosts(sortedData);
  };

  return (
    <AppContext.Provider
      value={{
        fetchBlogs,
        resourcesList,
        setResourcesList,
        parentCategories,
        setParentCategories,
        searchResults,
        setSearchResults,
        sortByViews,
        popularPosts,
        setPopularPosts,
        childCategories,
        setChildCategories,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };

import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Col, Container, Row } from "reactstrap";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import contactPageMapping from "../../mappings/contactPageMapping.js";
import ApiRequest from "../../utils/apiRequest.js";
import magicJson from "../../magicJson/magicJson.js";

const apiRequest = new ApiRequest();

const ContactContent = () => {
  const pagesData = useSelector((state) => state.pagesDataReducer.pagesData);

  const { id, formHeadings, formFields, buttonText, image, location } =
    contactPageMapping;

  const [loading, setLoading] = useState(true);
  const [isSubbmitting, setIsSubmitting] = useState(false);
  const [contactPageData, setContactPageData] = useState({});
  const [formHeadingsData, setFormHeadingsData] = useState({
    heading1: "",
    heading2: "",
  });

  const [locationDetailsData, setLocationDetailsData] = useState({
    locationIcon: "",
    address: "",
    emailIcon: "",
    emailid: "",
    phoneIcon: "",
    phoneNo: "",
  });
  const [formFieldsData, setFormFieldsData] = useState({
    nameLabel: "",
    name: "",
    namePlaceholder: "",
    emailLabel: "",
    email: "",
    emailPlaceholder: "",
    messageLabel: "",
    message: "",
    messagePlaceholder: "",
  });
  const [buttonData, setButtonData] = useState({ buttonText: "" });
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [imageData, setImageData] = useState({});

  useEffect(() => {
    const contactData = pagesData.find((item) => item._id === id);
    if (contactData) setContactPageData(contactData);
  }, [pagesData]);

  useEffect(() => {
    if (contactPageData.componentData) {
      // background image
      const backgrounImage = contactPageData.componentData.find(
        (item) => item.id === image.id
      );

      setImageData(backgrounImage);

      // location
      const locationDetails = contactPageData.componentData.find(
        (item) => item.id === location.id
      );

      if (locationDetails) {
        const locationIconData = locationDetails.childComponents?.find(
          (item) => item.id === location.locationIcon.id
        );
        const addressData = locationDetails.childComponents?.find(
          (item) => item.id === location.address.id
        );
        const emailIconData = locationDetails.childComponents?.find(
          (item) => item.id === location.emailIcon.id
        );
        const emailData = locationDetails.childComponents?.find(
          (item) => item.id === location.emailid.id
        );
        const phoneIconData = locationDetails.childComponents?.find(
          (item) => item.id === location.phoneIcon.id
        );
        const phoneNoData = locationDetails.childComponents?.find(
          (item) => item.id === location.phoneNo.id
        );

        setLocationDetailsData({
          locationIcon: locationIconData?.files[0]?.url || "",
          address: addressData?.value || "",
          emailIcon: emailIconData?.files[0]?.url || "",
          emailid: emailData?.value || "",
          phoneIcon: phoneIconData?.files[0]?.url || "",
          phoneNo: phoneNoData?.value || "",
        });
      }

      // Form Headings Data
      const formHeadingsData = contactPageData.componentData.find(
        (item) => item.id === formHeadings.id
      );

      if (formHeadingsData) {
        const heading1Data = formHeadingsData.childComponents?.find(
          (item) => item.id === formHeadings.heading1.id
        );

        const heading2Data = formHeadingsData.childComponents?.find(
          (item) => item.id === formHeadings.heading2.id
        );

        setFormHeadingsData({
          heading1: heading1Data?.value || "",
          heading2: heading2Data?.value || "",
        });
      }

      // Form Fields Data
      const formFieldsData = contactPageData.componentData.find(
        (item) => item.id === formFields.id
      );

      if (formFieldsData) {
        const nameLabelData = formFieldsData.childComponents?.find(
          (item) => item.id === formFields.nameLabel.id
        );
        const nameData = formFieldsData.childComponents?.find(
          (item) => item.id === formFields.name.id
        );

        const emailLabelData = formFieldsData.childComponents?.find(
          (item) => item.id === formFields.emailLabel.id
        );
        const emailData = formFieldsData.childComponents?.find(
          (item) => item.id === formFields.email.id
        );

        const messageLabelData = formFieldsData.childComponents?.find(
          (item) => item.id === formFields.messageLabel.id
        );
        const messageData = formFieldsData.childComponents?.find(
          (item) => item.id === formFields.message.id
        );

        setFormFieldsData({
          nameLabel: nameLabelData?.value || "",
          name: nameData?.defaultValue || "",
          namePlaceholder: nameData?.placeholder || "",
          emailLabel: emailLabelData?.value || nameLabelData?.value || "",
          email: emailData?.defaultValue || "",
          emailPlaceholder: emailData?.placeholder || "",
          messageLabel: messageLabelData?.value || nameLabelData?.value || "",
          message: messageData?.defaultValue || "",
          messagePlaceholder: messageData?.placeholder || "",
        });
      }

      // Button Data
      const buttonData = contactPageData.componentData.find(
        (item) => item.id === buttonText.id
      );

      if (buttonData) {
        setButtonData({
          buttonText: buttonData.value || "",
        });
      }

      setLoading(false);
    }
  }, [contactPageData]);

  // Validation function
  const validateFormField = (name, value) => {
    let errorMessage = "";

    if (name === "name") {
      const nameRegex = /^[a-zA-Z\s]+$/;
      if (value === "") {
        errorMessage = "Name is required.";
      } else if (!nameRegex.test(value)) {
        errorMessage = "Invalid Name. Only letters and spaces allowed.";
      }
    }

    if (name === "email") {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (value === "") {
        errorMessage = "Email Address is required.";
      } else if (!emailRegex.test(value)) {
        errorMessage = "Invalid Email Address.";
      }
    }

    if (name === "message") {
      if (value === "") {
        errorMessage = "Message is required.";
      }
    }

    return errorMessage;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormFieldsData((prev) => ({ ...prev, [name]: value }));

    // Validate field and set error message
    const errorMessage = validateFormField(name, value);
    if (name === "name") {
      setNameError(errorMessage);
    }
    if (name === "email") {
      setEmailError(errorMessage);
    }
    if (name === "message") {
      setMessageError(errorMessage);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, message } = formFieldsData;

    // Initialize errors as empty strings
    let nameError = validateFormField("name", name);
    let emailError = validateFormField("email", email);
    let messageError = validateFormField("message", message);

    // If there are any errors, set them and return early
    if (nameError || emailError || messageError) {
      setNameError(nameError);
      setEmailError(emailError);
      setMessageError(messageError);
      setIsSubmitting(false);
      return; // Stop form submission if there are errors
    }

    // Only set isSubmitting after validation is successful
    setIsSubmitting(true);

    try {
      magicJson.endpoint = "forms";
      magicJson.executor = "createContactUsResponses";
      magicJson.data = { name, email, message, comments: message };

      const result = await apiRequest.sendRequest(magicJson);
      if (result) {
        setFormFieldsData((prevFormFieldsData) => ({
          ...prevFormFieldsData,
          name: "",
          email: "",
          message: "",
        }));

        toast.remove();
        toast.success("Form Submitted Successfully");
        setIsSubmitting(false);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  if (loading) {
    return (
      <div className="loader">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="align-items-center mt-5">
            <Col lg={6}>
              <div className="section-title mt-4 mt-lg-0">
                <h3 className="title">{formHeadingsData.heading1}</h3>
                <p className="text-muted">{formHeadingsData.heading2}</p>
                <Form
                  className="contact-form mt-4"
                  id="myForm"
                  onSubmit={handleSubmit}
                >
                  <Row>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Form.Label>{formFieldsData?.nameLabel}</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={formFieldsData?.name}
                          onChange={handleChange}
                          placeholder={formFieldsData?.namePlaceholder}
                          disabled={isSubbmitting}
                        />
                        {nameError && (
                          <span className="text-danger">{nameError}</span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Form.Label>{formFieldsData?.emailLabel}</Form.Label>
                        <Form.Control
                          type="text"
                          name="email"
                          value={formFieldsData?.email}
                          onChange={handleChange}
                          placeholder={formFieldsData?.emailPlaceholder}
                          disabled={isSubbmitting}
                        />
                        {emailError && (
                          <span className="text-danger">{emailError}</span>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Form.Label>{formFieldsData?.messageLabel}</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="message"
                          value={formFieldsData?.message}
                          onChange={handleChange}
                          rows="4"
                          placeholder={formFieldsData?.messagePlaceholder}
                          disabled={isSubbmitting}
                        />
                        {messageError && (
                          <span className="text-danger">{messageError}</span>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubbmitting}
                  >
                    {isSubbmitting ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      buttonData.buttonText
                    )}
                  </button>
                </Form>
              </div>
            </Col>
            <Col lg={5} className="ms-auto order-first order-lg-last">
              <div className="text-center">
                <img
                  src={imageData?.files[0]?.url}
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="mt-4 pt-3">
                <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-map-marker"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">{locationDetailsData.address}</p>
                  </div>
                </div>
                <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-envelope"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">{locationDetailsData.emailid}</p>
                  </div>
                </div>
                <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-phone-alt"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">{locationDetailsData.phoneNo}</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="map">
        <iframe
          title="maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6509157.364974411!2d-123.79641389801948!3d37.193115265681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb9fe5f285e3d%3A0x8b5109a227086f55!2sCalifornia%2C%20USA!5e0!3m2!1sen!2sin!4v1628684675253!5m2!1sen!2sin"
          height="350"
          style={{ border: `0`, width: `100%` }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </React.Fragment>
  );
};

export default ContactContent;

import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../Alldata";
import { getcomponents } from "../../Alldata";
import blogsMapping from "../../../mappings/blogsMapping";
import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import Section from "../../../pages/Blog/BlogGrid/Section";
import BlogText from "../../Blog/BlogGrid/BlogText";
import PopularPost from "../../Blog/BlogGrid/PopularPost";
import BlogCategory from "../../Blog/BlogGrid/BlogCategory";
import Tags from "../../Blog/BlogGrid/Tags";
import SocialConnect from "../../Blog/BlogGrid/SocialConnect";
import { AppContext } from "../../../AppContext/AppContext";
import Pagination from "../../Jobs/JobList2/Pagination";

const BlogGrid = () => {
  document.title = "Blog Grid | Jobcy";

  const pagesData = useSelector((state) => state.pagesDataReducer.pagesData);
  const { pageTitle, breadcrumb, id } = blogsMapping;

  const [blogsPageData, setBlogsPageData] = useState({});

  const [breadcrumbs, setBreadcrumbs] = useState({
    pageTitle: "",
    breadcrumb1: "",
    breadcrumb2: "",
    breadcrumb3: "",
  });

  useEffect(() => {
    const blogData = pagesData.find((item) => item._id === id);
    if (blogData) {
      setBlogsPageData(blogData);
    }
  }, [pagesData]);

  useEffect(() => {
    try {
      if (blogsPageData.componentData) {
        //
        const title = blogsPageData.componentData?.find(
          (item) => item.id === pageTitle.id
        );

        // breadcrumb data --------------
        const breadcrumbData = blogsPageData.componentData.find(
          (item) => item.id === breadcrumb.id
        );

        const breadcrumb1Data = breadcrumbData.childComponents?.find(
          (item) => item.id === breadcrumb.breadcrumb1.id
        );

        const breadcrumb2Data = breadcrumbData.childComponents?.find(
          (item) => item.id === breadcrumb.breadcrumb2.id
        );

        const breadcrumb3Data = breadcrumbData.childComponents?.find(
          (item) => item.id === breadcrumb.breadcrumb3.id
        );

        setBreadcrumbs({
          pageTitle: title?.value || "",
          breadcrumb1: breadcrumb1Data?.value || "",
          breadcrumb2: breadcrumb2Data?.value || "",
          breadcrumb3: breadcrumb3Data?.value || "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [blogsPageData]);

  const [components, setComponents] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 4; // Number of posts per page
  const [loading, setLoading] = useState(true); // Loading state
  const [hasError, setHasError] = useState(false);
  const [firstrenderdata, setFirstrenderdata] = useState(false);

  const {
    resourcesList,
    searchResults,
    parentCategories,
    fetchBlogs,
    setSearchResults,
    sortByViews,
    childCategories,
  } = useContext(AppContext);

  const componentDataArray = components?.[0]?.map(
    ({ _id, componentData, createdBy, createdAt }) => ({
      _id,
      value: [
        ...componentData?.map((item, index) =>
          index === 3 ? item.options : item.value
        ),
        createdBy,
        new Date(createdAt).toISOString().split("T")[0], // Convert to 'YYYY-MM-DD' format
      ],
    })
  );

  const idtosent =
    Array.isArray(pagesData) &&
    pagesData.find((item) => item._id === blogsMapping.Post.id)?._id;

  useEffect(() => {
    // const token = localStorage.getItem("token");
    const token = process.env.REACT_APP_TOKEN;
    // setLoading(true);
    getcomponents(token, idtosent)
      .then((data) => {
        setComponents(data);
        // setLoading(false);

        // setTimeout(() => {
        //   setLoading(false);
        // }, 2500);
      })
      .catch((error) => {
        console.error("Error fetching components:", error);
        setHasError(true);
        // setLoading(false);
      });
  }, [pagesData]);

  const firstrender = () => {
    if (componentDataArray?.length > 0 && !firstrenderdata) {
      setSearchResults(componentDataArray);
      setFirstrenderdata(true);
      setLoading(false); // Set loading to false after 2 seconds
    }
  };

  useEffect(() => {
    if (!firstrenderdata) {
      firstrender();
    }
  }, [componentDataArray]);

  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    sortByViews();
  }, [resourcesList]);

  useEffect(() => {
    handleSearch();
  }, [query, selectedCategories, selectedTags, resourcesList]);

  // useEffect(() => {
  //   const fetchJobs = async () => {
  //     setLoading(true);
  //     setHasError(false);
  //     try {
  //       await new Promise((resolve, reject) => {
  //         // Simulate a fetching error for demonstration
  //         const success = true; // Change to false to simulate an error
  //         setTimeout(() => {
  //           success ? resolve() : reject(new Error("Failed to fetch jobs"));
  //         }, 400);
  //       });
  //     } catch (error) {
  //       console.error(error);
  //       setHasError(true);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchJobs();
  // }, []);

  const handleSearch = () => {
    const results =
      // Array.isArray(resourcesList) &&
      Array.isArray(componentDataArray) &&
      // resourcesList.filter((resource) => {
      componentDataArray.filter((resource) => {
        const matchesQuery =
          //     resource.title?.toLowerCase()?.includes(query.toLowerCase()) ||
          //     resource.content?.toLowerCase()?.includes(query.toLowerCase());
          resource?.value?.[1]?.toLowerCase()?.includes(query.toLowerCase()); //||
        // resource?.value?.[4]?.toLowerCase()?.includes(query.toLowerCase());

        const matchesCategory =
          selectedCategories?.length === 0 ||
          selectedCategories.includes(resource?.parentCategory);

        //   const matchesTags =
        //     selectedTags?.length === 0 ||
        //     selectedTags.includes(resource?.category); // Assuming `resource.tag` holds the blog's tag

        //   return matchesQuery && matchesCategory && matchesTags;
        return matchesQuery && matchesCategory;
      });
    setSearchResults(results);
    setCurrentPage(1); // Reset to the first page on search
  };

  const handleQueryChange = (newQuery) => {
    setQuery(newQuery);
  };

  const handleCategoryChange = (newCategories) => {
    setSelectedCategories(newCategories);
  };

  const handleTagSearch = (tags) => {
    setSelectedTags(tags);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll to top
    });
  };

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts =
    Array.isArray(searchResults) &&
    searchResults?.slice(indexOfFirstPost, indexOfLastPost);
  // const currentPosts =
  //   Array.isArray(componentDataArray) &&
  //   componentDataArray.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <React.Fragment>
      <Section breadcrumbs={breadcrumbs} />
      <section className="section">
        <Container>
          <Row>
            {loading ? (
              <Col lg={8} md={7}>
                <div className="blog-post">
                  <div className="loader">
                    <div className="spinner"></div>
                  </div>
                </div>
              </Col>
            ) : (
              <>
                <Col lg={8} md={7}>
                  <div className="blog-post">
                    {hasError ? (
                      <div className="mt-4">Error Found!</div>
                    ) : currentPosts?.length > 0 ? (
                      <BlogText
                        searchResults={currentPosts}
                        resourcesList={componentDataArray}
                      />
                    ) : (
                      `No Blogs Found!`
                    )}
                  </div>
                </Col>
                <Col lg={4} md={5}>
                  {resourcesList?.length >= 0 && !hasError && currentPosts && (
                    <div className="sidebar ms-lg-4 ps-lg-4 mt-5 mt-lg-0">
                      <BlogCategory
                        onSearch={handleQueryChange}
                        parentCategories={parentCategories}
                        selectedCategories={selectedCategories}
                        setSelectedCategories={handleCategoryChange}
                      />
                      {/* <PopularPost />
                      <Tags
                        childCategories={childCategories}
                        onSearch={handleTagSearch}
                      /> */}
                      <SocialConnect />
                    </div>
                  )}
                </Col>
              </>
            )}
          </Row>
        </Container>
        {loading ? (
          <div className="loader">
            <div className="spinner"></div>
          </div>
        ) : (
          searchResults?.length > 0 &&
          !hasError &&
          currentPosts && (
            <Pagination
              currentPage={currentPage}
              postsPerPage={postsPerPage}
              totalPosts={searchResults?.length}
              onPageChange={handlePageChange}
            />
          )
        )}
      </section>
    </React.Fragment>
  );
};

export default BlogGrid;

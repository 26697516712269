import { PAGES_DATA, COMPONENT_DATA } from "./actions";

const initialState = {
  pagesData: [],
  componentData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PAGES_DATA:
      return {
        ...state,
        pagesData: action.payload,
      };
    case COMPONENT_DATA:
      return {
        ...state,
        componentData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

import React, { useState, useEffect } from "react";
import { Col, Card, CardBody, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { addMinutes, formatDistanceToNow } from "date-fns";
import aloisLogo from "../../../assets/images/logo/akino-logo.png";

//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

//Import BlogImage
import blogImage1 from "../../../assets/images/blog/img-01.jpg";
import blogImage3 from "../../../assets/images/blog/img-03.jpg";
import blogImage12 from "../../../assets/images/blog/img-12.jpg";

import magicJson from "../../../magicJson/magicJson";
import ApplyNowForm from "../../Jobs/JobList/applyNowform";

const images = [blogImage1, blogImage3, blogImage12];

const RightSideContent = () => {
  //Lightbox
  const [photoIndex, setphotoIndex] = useState(0);
  const [isGallery, setisGallery] = useState(false);
  const [filteredrecentJobs, setfilteredrecentJobs] = useState([]);
  const [sortedJobs, setSortedJobs] = useState([]);
  const [jobId, setJobId] = useState("");
  const [isApplyFormOpen, setIsApplyFormOpen] = useState(false);

  const openApplyForm = () => {
    setIsApplyFormOpen(true);
  };

  const closeApplyform = () => {
    setIsApplyFormOpen(false);
  };

  const updateModalStateByChild = (value) => {
    setIsApplyFormOpen(value);
  };

  const getJobs = async () => {
    magicJson.endpoint = "jobs";
    magicJson.executor = "getActiveJobs";

    const data = new FormData();
    data.append("request", JSON.stringify(magicJson));

    const requestOptions = {
      method: "POST",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      body: data,
    };

    await fetch(process.env.REACT_APP_API_URL, requestOptions)
      .then((resp) => resp.json())
      .then((resp) => setfilteredrecentJobs(resp.data[0]));
  };

  useEffect(() => {
    getJobs();
  }, []);

  useEffect(() => {
    let arr = filteredrecentJobs;

    function parseDateTime(datetimeString) {
      // Replace space with T to fit ISO 8601 format
      if (datetimeString) {
        const isoDateTimeString = datetimeString.replace(" ", "T");
        return new Date(isoDateTimeString);
      }
    }

    arr?.sort((a, b) => {
      const dateA = parseDateTime(a.createdAt);
      const dateB = parseDateTime(b.createdAt);
      return dateB - dateA; // Sort in descending order
    });

    setSortedJobs(arr.slice(0, 3));
  }, [filteredrecentJobs]);

  const getTimeAgo = (createdAt) => {
    const date = new Date(createdAt);

    // IST is GMT+5:30 which is 330 minutes ahead
    const dateIST = addMinutes(date, 330);

    // Get the "time ago" string
    return formatDistanceToNow(dateIST, { addSuffix: true });
  };

  const handleApply = (id) => {
    openApplyForm();
    setJobId(id);
  };

  return (
    <React.Fragment>
      {isGallery ? (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          enableZoom={true}
          onCloseRequest={() => {
            setisGallery(false);
          }}
          onMovePrevRequest={() => {
            setphotoIndex((photoIndex + images.length - 1) % images.length);
          }}
          onMoveNextRequest={() => {
            setphotoIndex((photoIndex + 1) % images.length);
          }}
          imageCaption={"Project " + parseFloat(photoIndex + 1)}
        />
      ) : null}
      <Col lg={8}>
        <Card className="ms-lg-4 mt-4 mt-lg-0">
          <CardBody className="p-4">
            <div className="mb-5">
              <h6 className="fs-17 fw-medium mb-4">About Company</h6>
              <p className="text-muted">
                Objectively pursue diverse catalysts for change for
                interoperable meta-services. Distinctively re-engineer
                revolutionary meta-services and premium architectures.
                Intrinsically incubate intuitive opportunities and real-time
                potentialities. Appropriately communicate one-to-one technology.
              </p>

              <p className="text-muted">
                Intrinsically incubate intuitive opportunities and real-time
                potentialities Appropriately communicate one-to-one technology.
              </p>

              <p className="text-muted">
                Exercitation photo booth stumptown tote bag Banksy, elit small
                batch freegan sed. Craft beer elit seitan exercitation, photo
                booth et 8-bit kale chips proident chillwave deep v laborum.
                Aliquip veniam delectus, Marfa eiusmod Pinterest in do umami
                readymade swag.
              </p>
            </div>
            <div className="candidate-portfolio mb-5">
              <h6 className="fs-17 fw-medium mb-4">Gallery</h6>
              <Row className="g-3">
                <Col lg={6}>
                  <Link to="#" className="image-popup">
                    <img
                      src={blogImage1}
                      onClick={() => {
                        setisGallery(true);
                        setphotoIndex(0);
                      }}
                      alt=""
                      className="img-fluid"
                    />
                  </Link>
                </Col>
                <Col lg={6}>
                  <Link to="#" className="image-popup">
                    <img
                      src={blogImage3}
                      onClick={() => {
                        setisGallery(true);
                        setphotoIndex(1);
                      }}
                      alt=""
                      className="img-fluid"
                    />
                  </Link>
                </Col>
                <Col lg={12}>
                  <Link to="#" className="image-popup">
                    <img
                      src={blogImage12}
                      onClick={() => {
                        setisGallery(true);
                        setphotoIndex(2);
                      }}
                      alt=""
                      className="img-fluid"
                    />
                  </Link>
                </Col>
              </Row>
            </div>

            <div>
              <h6 className="fs-17 fw-medium mb-4">Current Opening</h6>

              {sortedJobs.map((filteredrecentJobsDetails, key) => (
                <div
                  key={key}
                  className={
                    filteredrecentJobsDetails.addclassNameBookmark === true
                      ? "job-box bookmark-post card mt-4"
                      : "job-box card mt-4"
                  }
                >
                  {/* <div
                    className="bookmark-label text-center"
                    onClick={handleClick}
                  >
                    <Link to="#" className="align-middle text-white">
                      <i className="mdi mdi-star"></i>
                    </Link>
                  </div> */}

                  <div className="p-4">
                    <Row>
                      <Col md={2}>
                        <div className="text-center mb-4 mb-md-0">
                          <Link to="/companydetails">
                            <img
                              src={aloisLogo}
                              alt="company-logo"
                              className="img-fluid rounded-3"
                            />
                          </Link>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="mb-2 mb-md-0">
                          <h5 className="fs-18 mb-0">
                            <Link
                              to={`/jobdetails/${filteredrecentJobsDetails._id}`}
                              className="text-dark"
                            >
                              {filteredrecentJobsDetails.title}
                            </Link>
                          </h5>
                          <p className="text-muted fs-14 mb-0">
                            {filteredrecentJobsDetails.companyName}
                          </p>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="d-flex mb-2">
                          <div className="flex-shrink-0">
                            <i className="mdi mdi-map-marker text-primary me-1"></i>
                          </div>
                          <p className="text-muted mb-0">
                            {[
                              filteredrecentJobsDetails.city,
                              filteredrecentJobsDetails.state,
                              filteredrecentJobsDetails.country,
                            ]
                              .filter(Boolean) // Filter out empty values
                              .map((part, index, array) => (
                                // Add <br /> between parts except the last one
                                <React.Fragment key={index}>
                                  {part}
                                  {index < array.length - 1 && ","}
                                  {index < array.length - 1 && <br />}
                                </React.Fragment>
                              ))}
                          </p>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="d-flex mb-0">
                          <div className="flex-shrink-0">
                            <i className="uil uil-clock-three text-primary me-1"></i>
                          </div>
                          <p className="text-muted mb-0">
                            {getTimeAgo(filteredrecentJobsDetails.createdAt)}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="p-3 bg-light-gray">
                    <Row className="justify-content-between">
                      <Col md={4}>
                        <div>
                          <p className="text-muted mb-0">
                            <span className="text-dark">Experience: </span>
                            {filteredrecentJobsDetails.minExp}-
                            {filteredrecentJobsDetails.maxExp} Years
                          </p>
                        </div>
                      </Col>
                      <Col lg={2} md={3}>
                        <div>
                          <Link
                            to="#applyNow"
                            onClick={() =>
                              handleApply(filteredrecentJobsDetails._id)
                            }
                            className="primary-link"
                          >
                            Apply Now{" "}
                            <i className="mdi mdi-chevron-double-right"></i>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              ))}
              {isApplyFormOpen && (
                <ApplyNowForm
                  show={isApplyFormOpen}
                  onClose={closeApplyform}
                  updateModalStateByChild={updateModalStateByChild}
                  jobId={jobId}
                ></ApplyNowForm>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RightSideContent;

import { useState, useEffect } from "react";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import magicJson from "../../magicJson/magicJson";
import processImage2 from "../../assets/images/process-02.png";

const Section = () => {
  const [filteredrecentData, setfilteredrecentData] = useState([]);

  const getFields = async () => {
    magicJson.endpoint = "contentTypeBuilder";
    magicJson.executor = "getPages";

    const data = new FormData();
    data.append("request", JSON.stringify(magicJson));

    const requestOptions = {
      method: "POST",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      body: data,
    };

    await fetch(process.env.REACT_APP_API_URL, requestOptions)
      .then((resp) => resp.json())
      .then((resp) => {
        setfilteredrecentData(resp.data[0][0].componentData);
        const title = resp.data[0][0].name;
        console.log("title", resp.data[0][0].name);
        localStorage.setItem("pagetitle", title);
      });
  };

  useEffect(() => {
    getFields();
  }, []);

  const labels = filteredrecentData.map((data) => data.label);
  console.log("label", labels);

  return (
    <React.Fragment>
      <div className="labels-container">
        <section className="bg-home2" id="home">
          <Container>
            <Row className="align-items-center">
              <Col lg={7}>
                <div className="mb-4 pb-3 me-lg-5">
                  <h6 className="sub-title">{labels[0]}</h6>
                  <h1 className="display-5 fw-semibold mb-3">
                    {labels[1]}
                    {/* <span className="text-primary fw-bold"> Jobcy</span> */}
                  </h1>
                  <p className="lead text-muted mb-0">{labels[2]}</p>
                </div>
              </Col>

              <Col lg={5}>
                <div className="mt-5 mt-md-0">
                  <img src={processImage2} alt="" className="home-img" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Section;

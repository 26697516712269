const playgroundMapping = {
  id: "6729a156030218171fb13ee3",

  textComponents: {
    id: "6c30a7d8-154c-47db-a727-2dadbb3336bd",
    title: {
      id: "9c3ef332-3f0c-4c9e-8d7c-5ace7d4bc8be",
    },
    description: {
      id: "2e2e0faf-eab7-42c9-9932-c7cd9d19db9b",
    },
    phoneNumber: {
      id: "d516d967-64e9-46f4-90ab-773a090a993d",
    },
  },

  formComponents: {
    id: "35fb8b8b-4d5a-43b7-a2ee-effcaef36f6d",
    email: {
      id: "51dd2366-6ca3-4c8d-9b3d-a475f423928f",
    },
    password: {
      id: "2c33624a-9275-4259-a2e2-9eaa308f84f8",
    },
  },

  enumAndArray: {
    id: "a9a828ce-c95f-4d98-b2ac-1e8dc201e069",
    enum: {
      id: "37925222-0448-4654-8bfb-94177eed4b18",
    },
    array: {
      id: "5631e524-eff8-4715-8651-6f87c8f91d58",
    },
  },

  infoForm: {
    id: "10c2064a-3310-4b29-b1ea-77277e8af6a9",
    name: {
      id: "159e2279-65a0-402a-bd7c-c3d63a022d4c",
    },
    country: {
      id: "ba11ce72-40bf-43df-bb8b-cb5db0df2e1a",
    },
    hobbies: {
      id: "e6817d8b-3495-4437-ae65-b1f38cad2fc8",
    },
    gender: {
      id: "9b33c7d2-6f50-4423-87c7-5526dac01105",
    },
    address: {
      id: "fcf03e59-0e04-42ca-82d1-4e12c9913b68",
    },
  },

  profileImage: {
    id: "5a660fee-5442-4c7c-bd62-a61618ad5043",
  },

  sampleImages: {
    id: "7f4d0d42-b2fe-45e7-b255-b077ef0756bf",
  },
};

export default playgroundMapping;

import React, { useContext } from "react";
import { Col, Row, Container } from "reactstrap";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { UserContext } from "../Alldata";
import homePageMapping from "../../mappings/homePageMapping";

const Cta = () => {
  const handleClick = () => {
    toast.remove();
    toast.error("Work in progress!");
  };
  // 1. get the data from context
  const { filteredrecentData, name } = useContext(UserContext);

  // 2. fetch the particular section data

  const myObject =
    Array.isArray(filteredrecentData) &&
    filteredrecentData
      .find((item) => item._id === homePageMapping.id)
      ?.componentData?.find(
        (item) => item._id === homePageMapping.BrowseOur5000LatestJobs.id
      )?.childComponents;

  const labels = myObject && myObject.map((item) => item.value);

  if (labels && labels.length > 0) {
    const element = document.getElementById("labels2");
    if (element) {
      element.innerHTML = labels[0];
    }
  }

  return (
    <React.Fragment>
      <section className="section bg-light">
        <Container className="container">
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="text-center">
                <h2 className="text-primary mb-4">
                  {/* Browse Our */}
                  <span className="text-warning fw-bold">{/* 5,000+  */}</span>
                  {/* Latest Jobs  */}
                </h2>
                <div id="labels2"></div>
                <p className="text-muted">
                  {/* Post a job to tell us about your project. We'll quickly match
                  you with the right freelancers. */}
                  {labels?.[1]}
                </p>
                <div className="mt-4 pt-2">
                  <Link to="/jobs" className="btn btn-primary btn-hover">
                    {labels?.[2]}
                    <i className="uil uil-rocket align-middle ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Cta;

import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const Pagination = ({ currentPage, postsPerPage, totalPosts, onPageChange }) => {
  const pageNumbers = [];
  

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg={12} className="mt-4 pt-2">
          <nav aria-label="Page navigation example">
            <ul className="pagination job-pagination mb-0 justify-content-center">
              <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                <Link className="page-link" to="#" tabIndex="-1" onClick={() => onPageChange(currentPage - 1)}>
                  <i className="mdi mdi-chevron-double-left fs-15"></i>
                </Link>
              </li>
              {pageNumbers.map(number => (
                <li key={number} className={`page-item ${number === currentPage ? "active" : ""}`}>
                  <Link className="page-link" to="#" onClick={() => onPageChange(number)}>
                    {number}
                  </Link>
                </li>
              ))}
              <li className={`page-item ${currentPage === pageNumbers.length ? "disabled" : ""}`}>
                <Link className="page-link" to="#" onClick={() => onPageChange(currentPage + 1)}>
                  <i className="mdi mdi-chevron-double-right fs-15"></i>
                </Link>
              </li>
            </ul>
          </nav>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Pagination;

import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Section from "../../Jobs/JobList/Section";
import JobSearchOptions from "./JobSearchOptions";
import JobVacancyList from "./JobVacancyList";
import Sidebar from "./Sidebar";
import magicJson from "../../../magicJson/magicJson";
import Pagination from "../JobList2/Pagination";
import { useSelector } from "react-redux";
import ApiRequest from "../../../utils/apiRequest";

const apiRequest = new ApiRequest();

const JobList = () => {
  document.title = "Job List | Jobcy";
  const pagesData = useSelector((state) => state.pagesDataReducer.pagesData);

  const [jobVacancyList, setJobVacancyList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [filteredJobList, setFilteredJobList] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(5); // Number of jobs per page

  // Access the state from the navigate function
  const location = useLocation();
  const { jobTitle, country } = location.state || {};
  const [jobsPageData, setJobPageData] = useState({});

  useEffect(() => {
    for (let item of pagesData) {
      if (item.name === "Jobs") setJobPageData(item);
    }
  }, [pagesData]);

  const getJobs = async () => {
    magicJson.endpoint = "jobs";
    magicJson.executor = "getActiveJobs";

    const resp = await apiRequest.sendRequest(magicJson);
    if (resp) {
      const jobs = resp.data[0];

      // store in the local state
      localStorage.setItem("jobVacancyList", JSON.stringify(jobs));

      // Combined filter for job title and country
      const filteredJobs =
        Array.isArray(jobs) &&
        jobs.filter((job) => {
          const matchesTitle = job.title
            ?.toLowerCase()
            .includes(jobTitle?.toLowerCase() || "");
          const matchesCountry =
            !country || country === "all" || job.country === country;

          return matchesTitle && matchesCountry;
        });

      setJobVacancyList(filteredJobs);
      setFilteredJobList(filteredJobs); // Show all jobs initially
    }
  };

  useEffect(() => {
    getJobs();
  }, []);

  useEffect(() => {
    filterJobs();
  }, [selectedExperience, selectedSkills, selectedDateRange]);

  const handleFilterClick = (e) => {
    e.preventDefault();
    filterJobs();
  };

  const filterJobs = () => {
    const data = localStorage.getItem("jobVacancyList");
    const parsedData = JSON.parse(data);
    setJobVacancyList(parsedData);

    const currentDate = new Date();
    const now = new Date(currentDate.getTime() - (5 * 60 * 60 * 1000 + 30));

    const filtered = jobVacancyList?.filter((job) => {
      const jobDate = new Date(job.createdAt);

      const matchesSearchTerm =
        job.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        job.jobDescription?.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesCountry =
        selectedCountry && selectedCountry.value !== "all"
          ? job.country === selectedCountry.label
          : true;

      const matchesJobType =
        selectedJobType && selectedJobType.value !== "all"
          ? job.skills.some((skill) => skill.toLowerCase() === selectedJobType.label.toLowerCase())
          : true;

      const matchesExperience = selectedExperience
        ? selectedExperience === "no-experience"
          ? job.maxExp === 0
          : selectedExperience === "0-3-years"
            ? job.maxExp >= 0 && job.maxExp <= 3
            : selectedExperience === "3-6-years"
              ? job.maxExp >= 3 && job.maxExp <= 6
              : selectedExperience === "more-than-6-years"
                ? job.maxExp > 6
                : true
        : true;

      const matchesSkills =
        selectedSkills.length > 0
          ? selectedSkills.some((skill) => job.skills.includes(skill))
          : true;

      const matchesDateRange = selectedDateRange
        ? (() => {
          const { value } = selectedDateRange;
          switch (value) {
            case "last-hour":
              return now - jobDate <= 60 * 60 * 1000;
            case "last-24-hours":
              return now - jobDate <= 24 * 60 * 60 * 1000;
            case "last-7-days":
              return now - jobDate <= 7 * 24 * 60 * 60 * 1000;
            case "last-14-days":
              return now - jobDate <= 14 * 24 * 60 * 60 * 1000;
            case "last-30-days":
              return now - jobDate <= 30 * 24 * 60 * 60 * 1000;
            default:
              return true;
          }
        })()
        : true;

      return (
        matchesSearchTerm &&
        matchesCountry &&
        matchesJobType &&
        matchesExperience &&
        matchesSkills &&
        matchesDateRange
      );
    });

    setFilteredJobList(filtered);
    setCurrentPage(1);
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handleJobTypeChange = (selectedOption) => {
    setSelectedJobType(selectedOption);
  };

  const handleExperienceChange = (selectedOption) => {
    if (selectedExperience === selectedOption) {
      setSelectedExperience(null);
    } else {
      setSelectedExperience(selectedOption);
    }
  };

  const handleSkillChange = (skill) => {
    if (selectedSkills.includes(skill)) {
      setSelectedSkills(selectedSkills.filter((s) => s !== skill));
    } else {
      setSelectedSkills([...selectedSkills, skill]);
    }
  };

  const handleDateRangeChange = (value) => {
    setSelectedDateRange(value);
  };

  // Pagination logic
  const indexOfLastPost = currentPage * jobsPerPage;
  const indexOfFirstPost = indexOfLastPost - jobsPerPage;
  const currentPosts =
    Array.isArray(filteredJobList) &&
    filteredJobList.slice(indexOfFirstPost, indexOfLastPost);

  // skills on the current page
  const skillsOnCurrentPage =
    currentPosts &&
    Array.from(new Set(currentPosts.flatMap((job) => job.skills)));

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll to top
    });
  };

  return (
    <React.Fragment>
      <Section />

      <section className="section">
        <Container>
          <Row>
            <Col lg={9}>
              <div className="me-lg-5">
                {filteredJobList?.length >= 0 ? (
                  <JobSearchOptions
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    onCountryChange={handleCountryChange}
                    onJobTypeChange={handleJobTypeChange}
                    onFilterClick={handleFilterClick}
                    jobVacancyList={jobVacancyList}
                  />
                ) : (
                  ""
                )}
                <JobVacancyList jobVacancyList={currentPosts} />
              </div>
            </Col>

            {filteredJobList?.length >= 0 ? (
              <Sidebar
                jobVacancyList={jobVacancyList}
                onExperienceChange={handleExperienceChange}
                selectedExperience={selectedExperience}
                selectedSkills={selectedSkills}
                setSelectedSkills={setSelectedSkills}
                handleSkillChange={handleSkillChange}
                handleDateRangeChange={handleDateRangeChange}
                selectedDateRange={selectedDateRange}
                skillsOnCurrentPage={skillsOnCurrentPage}
              />
            ) : (
              ""
            )}
          </Row>

          {filteredJobList?.length >= 0 ? (
            <Pagination
              currentPage={currentPage}
              postsPerPage={jobsPerPage}
              totalPosts={filteredJobList.length}
              onPageChange={onPageChange}
            />
          ) : (
            ""
          )}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JobList;

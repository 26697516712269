const blogsMapping = {
  id: "6731a1047128df65b0442da7",
  pageTitle: {
    id: "20233893-8949-4525-a39b-9be678bd1c78",
  },
  breadcrumb: {
    id: "be04e94b-af56-44b7-a805-62f25320f8b9",
    breadcrumb1: {
      id: "869ff7dc-125c-415a-8bdb-0f627a1823d3",
    },
    breadcrumb2: {
      id: "c81efc91-f41e-4177-9b7c-61d33a920397",
    },
    breadcrumb3: {
      id: "8cb4cd8a-6101-46db-973e-5fe4dbb4ce77",
    },
  },
  Post: {
    id: "67319c727128df65b0442da4",
  },
};

export default blogsMapping;

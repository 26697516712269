import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import JobDetailsDescription from "./JobDetailsDescription";
import JobVacancyPost from "./JobVacancyPost";
import RightSideContent from "./RightSideContent";
import Section from "./Section";
import { useParams } from "react-router-dom";
import magicJson from "../../../magicJson/magicJson";

const JobDetails = () => {
  document.title = "Job Details | Jobcy";

  const [job, setJob] = useState({});
  const { id } = useParams();

  useEffect(() => {
    async function getJobById() {
      let data = new FormData();

      magicJson.endpoint = "jobs";
      magicJson.executor = "getJobsById";
      magicJson.data = [{ id }];

      data.append("request", JSON.stringify(magicJson));
      let requestOptions = {
        method: "POST",
        body: data,
      };

      await fetch(process.env.REACT_APP_API_URL, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => setJob(resp.data[0][0]));

      console.log(job);
    }

    getJobById();
  }, [id]);

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <Row>
            <Col lg={8}>
              <JobDetailsDescription job={job} />
              <JobVacancyPost />
            </Col>
            <Col lg={4} className="mt-4 mt-lg-0">
              <RightSideContent job={job} />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JobDetails;

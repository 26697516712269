import React, { useState, useContext } from "react";
import { Col, Container, Row, Form } from "reactstrap";
import { useNavigate } from "react-router-dom";
import CountryOptions from "../SubSection/CountryOptions";
import JobSearch from "../SubSection/JobSearch";
import toast from "react-hot-toast";

const handleClick = () => {
  toast.remove();
  toast.error("Work in progress!");
};

const Section = ({ labels, processImage }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedJobTitle, setSelectedJobTitle] = useState("");
  const navigate = useNavigate();

  const handleJobTitleChange = (selectedTitle) => {
    setSelectedJobTitle(selectedTitle);
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption.label);
  };

  const handleJobData = () => {
    navigate("/jobs", {
      state: {
        jobTitle: selectedJobTitle,
        country: selectedCountry,
      },
    }); // Navigate to the job list page
  };

  return (
    <React.Fragment>
      <section className="bg-home2" id="home">
        <Container>
          <Row className="align-items-center">
            <Col lg={7}>
              <div className="mb-4 pb-3 me-lg-5">
                {/* <h6 className="sub-title">We have 150,000+ live jobs</h6> */}
                {/* <h6 className="sub-title">{labels.length > 0 ? labels[0] : ""}</h6> */}
                <h6 className="sub-title">{labels.text}</h6>

                <h1 dangerouslySetInnerHTML={{ __html: labels.titleText }} />
                <p className="lead text-muted mb-0">
                  {/* Find jobs, create trackable resumes and enrich your
                  applications.Carefully crafted after analyzing the needs of
                  different industries. */}
                  {labels.description}
                </p>
              </div>
              <Form action="#">
                <div className="registration-form">
                  <Row className="g-0">
                    <Col md={4}>
                      <div className="filter-search-form filter-border mt-1 mt-md-0 ">
                        <i className="uil uil-briefcase-alt"></i>
                        <JobSearch onTitleChange={handleJobTitleChange} />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="filter-search-form mt-3 mt-md-0">
                        <i className="uil uil-map-marker"></i>
                        <CountryOptions onCountryChange={handleCountryChange} />
                      </div>
                    </Col>
                    <Col md={4}>
                      {/* <Link to=""> */}
                      <div className="mt-3 mt-md-0 h-100">
                        <button
                          className="btn btn-primary submit-btn w-100 h-100"
                          type="button"
                          onClick={handleJobData}
                        >
                          <i className="uil uil-search me-1"></i> Find Job
                        </button>
                      </div>
                      {/* </Link> */}
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>

            <Col lg={5}>
              <div className="mt-5 mt-md-0">
                <img src={processImage} alt="image" className="home-img" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;

import React, { useEffect, useState } from "react";
import About from "../../../pages/Company/AboutUs/About";
import Section from "../../../pages/Company/AboutUs/Section";
import Counter from "../../../pages/Company/AboutUs/Counter";
import Features from "../../../pages/Company/AboutUs/Features";
import Cta from "../../../pages/Company/AboutUs/Cta";
import CompanyTestimonal from "../../../pages/Company/AboutUs/CompanyTestimonal";
import aboutusPageMapping from "../../../mappings/aboutusPageMapping";
import { useSelector } from "react-redux";
import { getcomponents } from "../../Alldata";

const AboutUs = () => {
  document.title = "About Us | Jobcy";
  const pagesData = useSelector((state) => state.pagesDataReducer.pagesData);
  const {
    breadcrumb,
    aboutUs,
    keyFeatures,
    freeTrial,
    numbers,
    companyTestimonial,
    id,
  } = aboutusPageMapping;

  const [aboutusPageData, setAboutusPageData] = useState({});

  const [breadcrumbs, setBreadcrumbs] = useState({
    breadcrumb1: "",
    breadcrumb2: "",
    breadcrumb3: "",
  });
  const [aboutSectionData, setAboutSectionData] = useState({});
  const [keyFeatureSectionData, setKeyFeatureSectionData] = useState({});
  const [counterSectionData, setCounterSectionData] = useState({});
  const [ctaSectionData, setCtaSectionData] = useState({});
  const [testimonialSectionData, setTestimonialSectionData] = useState({});

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const aboutData = pagesData.find((item) => item._id === id);
    if (aboutData) {
      setAboutusPageData(aboutData);
    }
  }, [pagesData]);

  useEffect(() => {
    try {
      if (aboutusPageData.componentData) {
        // breadcrumb data --------------
        const breadcrumbData = aboutusPageData.componentData.find(
          (item) => item.id === breadcrumb.id
        );

        const breadcrumb1Data = breadcrumbData.childComponents?.find(
          (item) => item.id === breadcrumb.breadcrumb1.id
        );

        const breadcrumb2Data = breadcrumbData.childComponents?.find(
          (item) => item.id === breadcrumb.breadcrumb2.id
        );

        const breadcrumb3Data = breadcrumbData.childComponents?.find(
          (item) => item.id === breadcrumb.breadcrumb3.id
        );

        // about section data ----------------
        const about = aboutusPageData.componentData?.find(
          (item) => item.id === aboutUs.id
        );

        const aboutUsMain = about.childComponents?.find(
          (item) => item.id === aboutUs.aboutUs.id
        );
        const title1 = about.childComponents?.find(
          (item) => item.id === aboutUs["title-1"].id
        );
        const description = about.childComponents?.find(
          (item) => item.id === aboutUs.description.id
        );
        const image = about.childComponents?.find(
          (item) => item.id === aboutUs.image.id
        )?.files.map((item) => item.url);

        console.log("image", image);
        const linkLabel = about.childComponents
          ?.find((item) => item.id === aboutUs.link.id)
          .childComponents.find((it) => it.id === aboutUs.link.linkName.id);

        // features section data -----------------
        const fetchFeatures = async () => {
          const keyFeat = aboutusPageData.componentData.find(
            (item) => item.id === keyFeatures.id
          );

          const keyFeatLabel = keyFeat?.childComponents?.find(
            (item) => item.id === keyFeatures.keyFeatures.id
          );

          const keyFeatTitle = keyFeat?.childComponents?.find(
            (item) => item.id === keyFeatures["title-1"].id
          );

          const featuresCollectionId = keyFeat?.childComponents?.find(
            (item) => item.id === keyFeatures.keyFeaturesName.id
          )?._id;

          if (featuresCollectionId) {
            try {
              const res = await getcomponents("dsa", featuresCollectionId);
              const features =
                res[0].map((item) => ({
                  featureTitle: item?.componentData[0]?.value || "",
                  featureImg: item?.componentData[1]?.value || "",
                })) || [];

              setKeyFeatureSectionData({
                keyFeatureLabel: keyFeatLabel?.value || "",
                keyFeatTitle: keyFeatTitle?.value || "",
                features,
              });
            } catch (error) {
              console.error("Error fetching features:", error);
            }
          }
        };

        // counters section data --------------
        const fetchCouter = async () => {
          const counterCollectionId = aboutusPageData.componentData.find(
            (item) => item.id === numbers.id
          )._id;

          if (counterCollectionId) {
            try {
              const res = await getcomponents("", counterCollectionId);
              const counters =
                res[0]?.map((item) => ({
                  countTitle: item.componentData[0].value || "",
                  count: item.componentData[1].value || 0,
                })) || [];

              setCounterSectionData({
                counters,
              });
            } catch (error) {
              console.error("Error fetching counters");
            }
          }
        };

        // cta section data ----------------
        const cta = aboutusPageData.componentData.find(
          (item) => item.id === freeTrial.id
        );

        const ctaTitle = cta.childComponents?.find(
          (item) => item.id === freeTrial.titleName.id
        );

        const ctaDescription = cta.childComponents?.find(
          (item) => item.id === freeTrial.description.id
        );

        const getStartedLink = cta.childComponents?.find(
          (item) => item.id === freeTrial.link1.id
        );

        const freeTrialLink = cta.childComponents?.find(
          (item) => item.id === freeTrial.link2.id
        );

        // company testimonial section data ---------------
        const testimonial = aboutusPageData.componentData.find(
          (item) => item.id === companyTestimonial.id
        );

        const testimonialTitle = testimonial.childComponents.find(
          (item) => item.id === companyTestimonial.titleName.id
        );

        const testimonialDescription = testimonial.childComponents.find(
          (item) => item.id === companyTestimonial.description.id
        );

        const testimonialImages = testimonial.childComponents.find(
          (item) => item.id === companyTestimonial.images.id
        );

        const testimonialSocials = testimonial.childComponents
          .find((item) => item.id === companyTestimonial.socialMediaOption.id)
          .childComponents.map((it) => ({
            socialIcon: it.childComponents[0].files || "",
            socialUrl: it.childComponents[1].value || "",
          }));

        const testimonialLink = testimonial.childComponents.find(
          (item) => item.id === companyTestimonial.link.id
        );

        fetchFeatures();
        fetchCouter();

        setBreadcrumbs({
          breadcrumb1: breadcrumb1Data?.value || "",
          breadcrumb2: breadcrumb2Data?.value || "",
          breadcrumb3: breadcrumb3Data?.value || "",
        });

        setAboutSectionData({
          aboutMain: aboutUsMain?.value || "",
          title1: title1?.value || "",
          description: description?.value || "",
          imageSrc: image || [],
          link: {
            linkLabel: linkLabel?.value || "",
          },
        });

        setCtaSectionData({
          ctaTitle: ctaTitle.value || "",
          ctaDescription: ctaDescription.value || "",
          getStartedLink: {
            linkName: getStartedLink.childComponents[0].value || "",
            linkUrl: getStartedLink.childComponents[1].value || "",
            linkLogo: getStartedLink.childComponents[2].value || "",
          },
          freeTrialLink: {
            linkName: freeTrialLink.childComponents[0].value || "",
            linkUrl: freeTrialLink.childComponents[1].value || "",
            linkLogo: freeTrialLink.childComponents[2].value || "",
          },
        });

        setTestimonialSectionData({
          testimonialTitle: testimonialTitle.value || "",
          testimonialDescription: testimonialDescription.value || "",
          testimonialImages: testimonialImages.files || [],
          testimonialSocials: testimonialSocials || [],
          testimonialLink: {
            linkName: testimonialLink.childComponents[0].value || "",
            linkUrl: testimonialLink.childComponents[1].value || "",
          },
        });

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [aboutusPageData]);

  if (loading) {
    return (
      <div className="loader">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Section breadcrumbs={breadcrumbs} />
      <About aboutSectionData={aboutSectionData} />
      <Counter counterSectionData={counterSectionData} />
      <Features keyFeatureSectionData={keyFeatureSectionData} />
      <Cta ctaSectionData={ctaSectionData} />
      <CompanyTestimonal testimonialSectionData={testimonialSectionData} />
    </React.Fragment>
  );
};

export default AboutUs;

import React, { useEffect, useState } from "react";
import Section from "./Section";
import { useSelector } from "react-redux";
import "./playground.css";
import { info } from "sass";

function Playground() {
  const pagesData = useSelector((state) => state.pagesDataReducer.pagesData);
  const [playgroundData, setPlaygroundData] = useState({});
  const [textData, setTextData] = useState({});
  const [form, setForm] = useState({});
  const [enumData, setEnumData] = useState({});
  const [infoForm, setInfoForm] = useState({});
  const [profileImage, setProfileImage] = useState({});
  const [multiMedia, setMultiMedia] = useState({});

  useEffect(() => {
    for (let item of pagesData) {
      if (item.name === "Playground") setPlaygroundData(item);
    }
  }, [pagesData]);

  useEffect(() => {
    if (playgroundData.componentData) {
      for (let item of playgroundData.componentData) {
        if (item.label === "Text Components") setTextData(item);
        if (item.label === "Form") setForm(item);
        if (item.label === "Enum and Array") setEnumData(item);
        if (item.label === "Info Form") setInfoForm(item);
        if (item.label === "Profile Image") setProfileImage(item);
        if (item.label === "Sample images") setMultiMedia(item);
      }
    }
  }, [playgroundData]);

  return (
    <React.Fragment>
      <Section />
      <div className="playground-parent">
        <div className="profileImage">
          <img
            className="profile-image"
            src={profileImage.files && profileImage.files[0].url}
          />
        </div>

        <div className="text-comp">
          <h1 className="text-comp-title">Text Component</h1>
          <h2>
            {textData.childComponents && textData.childComponents[0].value}
          </h2>
          <p>{textData.childComponents && textData.childComponents[1].value}</p>
          <b>Phone No. : </b>{" "}
          <p>{textData.childComponents && textData.childComponents[2].value}</p>
        </div>

        <div className="credentials-comp">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <h1 className="credentials-comp-title">Form</h1>
            <b>Email </b>
            <input
              type={
                form.childComponents &&
                form.childComponents[0].componentName.toLowerCase()
              }
              placeholder={
                form.childComponents && form.childComponents[0].placeholder
              }
              value={
                form.childComponents && form.childComponents[0].defaultValue
              }
              required={
                form.childComponents && form.childComponents[0].isRequired
              }
              maxLength={
                form.childComponents && form.childComponents[0].maximumLength
              }
            />
            <br />
            <b>Password </b>
            <input
              type={
                form.childComponents &&
                form.childComponents[1].componentName.toLowerCase()
              }
              placeholder={
                form.childComponents && form.childComponents[1].placeholder
              }
              value={
                form.childComponents && form.childComponents[1].defaultValue
              }
              required={
                form.childComponents &&
                form.childComponents[1].isRequired == true
                  ? true
                  : false
              }
              maxLength={
                form.childComponents && form.childComponents[1].maximumLength
              }
              minLength={
                form.childComponents && form.childComponents[1].minimumLength
              }
            />
            <br />
            <button type="submit">Submit</button>
          </form>
        </div>

        <div className="info-form">
          <h1>Information Form</h1>
          <form>
            <div className="info-inputs">
              <div className="name-input">
                <b>
                  {infoForm.childComponents &&
                    infoForm.childComponents[0].label}
                </b>
                <input
                  type={
                    infoForm.childComponents &&
                    infoForm.childComponents[0].componentName === "Short Text"
                      ? "text"
                      : null
                  }
                  placeholder={
                    infoForm.childComponents &&
                    infoForm.childComponents[0].placeholder
                  }
                  required={
                    infoForm.childComponents &&
                    infoForm.childComponents[0].isRequired == true
                      ? true
                      : false
                  }
                  value={
                    infoForm.childComponents &&
                    infoForm.childComponents[0].defaultValue
                  }
                  minLength={
                    infoForm.childComponents &&
                    infoForm.childComponents[0].minimumLength
                  }
                  maxLength={
                    infoForm.childComponents &&
                    infoForm.childComponents[0].maximumLength
                  }
                  pattern={
                    infoForm.childComponents &&
                    infoForm.childComponents[0].regExp
                  }
                />
              </div>

              <b>
                {infoForm.childComponents && infoForm.childComponents[1].label}
              </b>
              <div className="country-dropdown">
                <select
                  required={
                    infoForm.childComponents &&
                    infoForm.childComponents[1].isRequired == true
                      ? true
                      : false
                  }
                  value={
                    infoForm.childComponents &&
                    infoForm.childComponents[1].defaultValue
                  }
                >
                  {infoForm.childComponents &&
                    infoForm.childComponents[1].options.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                </select>
              </div>

              <div className="hobbies-checkbox">
                <b>
                  {infoForm.childComponents &&
                    infoForm.childComponents[2].label}
                </b>
                {infoForm.childComponents &&
                  infoForm.childComponents[2].options.map((option, index) => (
                    <label key={index}>
                      <input
                        type={
                          infoForm.childComponents &&
                          infoForm.childComponents[2].componentName.toLowerCase()
                        }
                        required={
                          infoForm.childComponents &&
                          infoForm.childComponents[2].isRequired == true
                            ? true
                            : false
                        }
                      />
                      {option}
                    </label>
                  ))}
              </div>

              <div className="gender-radio">
                <b>
                  {infoForm.childComponents &&
                    infoForm.childComponents[3].label}
                </b>
                {infoForm.childComponents &&
                  infoForm.childComponents[3].options.map((option, index) => (
                    <label key={index}>
                      <input
                        type="radio"
                        name="gender-radio"
                        value={option}
                        required={
                          infoForm.childComponents &&
                          infoForm.childComponents[3].isRequired
                        }
                      />
                      {option}
                    </label>
                  ))}
              </div>

              <div className="address-textarea">
                <b>
                  {infoForm.childComponents &&
                    infoForm.childComponents[4].label}
                </b>
                <br />
                <textarea
                  required={
                    infoForm.childComponents &&
                    infoForm.childComponents[4].isRequired == true
                      ? true
                      : false
                  }
                  placeholder={
                    infoForm.childComponents &&
                    infoForm.childComponents[4].placeholder
                  }
                  value={
                    infoForm.childComponents &&
                    infoForm.childComponents[4].defaultValue
                  }
                  minLength={
                    infoForm.childComponents &&
                    infoForm.childComponents[4].minimunLength
                  }
                  maxLength={
                    infoForm.childComponents &&
                    infoForm.childComponents[4].maximumLength
                  }
                />
              </div>
            </div>
            <button type="subtmit">Submit</button>
          </form>
        </div>

        <div className="enum-comp">
          <h1 className="enum-comp-title">
            Enumeration Component{" "}
            {enumData.allowMultiple == true
              ? " (Multiple Alloweed)"
              : " (Multiple Not Allowed)"}
          </h1>
          <h3>All Options :</h3>
          <div className="all-options">
            {enumData.childComponents &&
              enumData.childComponents[0].options.map((item, index) => (
                <div className="all-enum-options">{item}</div>
              ))}
          </div>
          <h3>Selected Options :</h3>
          <div className="selected-options">
            {enumData.childComponents &&
              enumData.childComponents[0].value.map((item, index) => (
                <div className="selected-enum-options">{item}</div>
              ))}
          </div>
        </div>

        <h1 className="multi-media-title">Multi Media</h1>
        <div className="multi-media-comp">
          {multiMedia.files &&
            multiMedia.files.map((file, index) => (
              <div className="multi-media-file">
                <img src={file.url} alt={file.name} />
              </div>
            ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Playground;

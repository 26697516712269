import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import magicJson from "../../../magicJson/magicJson";
import toast from "react-hot-toast";
import "./rightSideContent.css";
import ApplyNowForm from "../JobList/applyNowform";

const RightSideContent = ({ job }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [resume, setResume] = useState(null);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [isApplyFormOpen, setIsApplyFormOpen] = useState(false);
  const { id } = useParams();

  const openApplyForm = () => {
    setIsApplyFormOpen(true);
  };

  const closeApplyform = () => {
    setIsApplyFormOpen(false);
  };

  const updateModalStateByChild = (value) => {
    setIsApplyFormOpen(value);
  };

  const openModal = () => {
    openApplyForm();
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!resume) newErrors.resume = "Resume is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleApply = (event) => {
    event.preventDefault();

    if (validateForm()) {
      const data = new FormData();

      try {
        magicJson.endpoint = "apply";
        magicJson.executor = "createCandidate";
        magicJson.data = [
          {
            jobId: id,
            name: formData.name,
            email: formData.email,
            message: formData.message,
          },
        ];

        data.append("request", JSON.stringify(magicJson));
        data.append("files", resume);
        const requestOptions = {
          method: "POST",
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          body: data,
        };

        fetch(process.env.REACT_APP_API_URL, requestOptions).then((resp) => {
          resp.json().then(() => {
            setFormData({ name: "", email: "", message: "" });
            setResume(null);
            openModal();
            toast.remove();
            toast.success("Applied Successfully!");
          });
        });
      } catch (error) {
        console.log("error", error.message);
      }
    } else {
      setSubmitted(true);
    }
  };

  const handleClick = () => {
    toast.remove();
    toast.error("Work in progress!");
  };

  return (
    <React.Fragment>
      <div className="side-bar ms-lg-4">
        <Card className="job-overview">
          <CardBody className="p-4">
            <h6 className="fs-17">Job Overview</h6>
            <ul className="list-unstyled mt-4 mb-0">
              <li>
                <div className="d-flex mt-4">
                  <i
                    className="uil uil-user icon bg-primary-subtle text-primary"
                    id="jobDescIcon"
                  ></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Job Title</h6>
                    <p className="text-muted mb-0">{job.title}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i
                    className="uil uil-star-half-alt icon bg-primary-subtle text-primary"
                    id="jobDescIcon"
                  ></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Experience</h6>
                    <p className="text-muted mb-0">{`${job.minExp}-${job.maxExp} Years`}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-location-point icon bg-primary-subtle text-primary jobDescIcon"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Location</h6>
                    <p className="text-muted mb-0">
                      {[job.city, job.state, job.country]
                        .filter(Boolean) // Filter out empty values
                        .map((part, index, array) => (
                          // Add <br /> between parts except the last one
                          <React.Fragment key={index}>
                            {part}
                            {index < array.length - 1 && ","}
                            {index < array.length - 1 && <br />}
                          </React.Fragment>
                        ))}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i
                    className="uil uil-usd-circle icon bg-primary-subtle text-primary"
                    id="jobDescIcon"
                  ></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Offered Salary</h6>
                    <p className="text-muted mb-0">
                      {`${job.minSalary}-${job.maxSalary} ${job.currency} ${job.payscale}`}
                    </p>
                  </div>
                </div>
              </li>
              {/* <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-graduation-cap icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Qualification</h6>
                    <p className="text-muted mb-0">Bachelor Degree</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-building icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Industry</h6>
                    <p className="text-muted mb-0">Private</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-history icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Date Posted</h6>
                    <p className="text-muted mb-0">Posted 2 hrs ago</p>
                  </div>
                </div>
              </li> */}
            </ul>
            <div className="mt-3">
              <Link
                to="#applyNow"
                onClick={openModal}
                className="btn btn-primary btn-hover w-100 mt-2"
              >
                Apply Now<i className="uil uil-arrow-right"></i>
              </Link>
              <Link
                to="#"
                // to="/bookmarkjobs"
                onClick={handleClick}
                className="btn btn-soft-warning btn-hover w-100 mt-2"
              >
                <i className="uil uil-bookmark"></i> Add Bookmark
              </Link>
            </div>
          </CardBody>
        </Card>

        <div className="mt-4">
          <h6 className="fs-16 mb-3">Job location</h6>
          <iframe
            title="maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.15830869428!2d-74.119763973046!3d40.69766374874431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1628067715234!5m2!1sen!2sin"
            style={{ width: `100%`, height: `250px` }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
        {isApplyFormOpen && (
          <ApplyNowForm
            show={isApplyFormOpen}
            onClose={closeApplyform}
            updateModalStateByChild={updateModalStateByChild}
            jobId={id}
          ></ApplyNowForm>
        )}
      </div>
    </React.Fragment>
  );
};

export default RightSideContent;

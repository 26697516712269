import React, { useState, useEffect } from "react";
import { getcomponents } from "../../Alldata";
import blogsMapping from "../../../mappings/blogsMapping";
import { useSelector } from "react-redux";
import { Container, Col, Row, Card } from "reactstrap";
import Section from "../BlogDetails/Section";
import BlogTitle from "../BlogDetails/BlogTitle";
import BlogCategory from "../BlogGrid/BlogCategory";
import PopularPost from "../BlogGrid/PopularPost";
import TextWidget from "../BlogGrid/TextWidget";
import Archives from "../BlogGrid/Archives";
import Tags from "../BlogGrid/Tags";
import SocialConnect from "../BlogGrid/SocialConnect";
import BlogSwiper from "../BlogDetails/BlogSwiper";
import BlogColumn from "../BlogDetails/BlogColumn";
import BlogComments from "../BlogDetails/BlogComments";
import BlogForm from "../BlogDetails/BlogForm";
import BlogPost from "../BlogDetails/BlogPost";
import { useParams } from "react-router-dom";
import magicJson from "../../../magicJson/magicJson";

const BlogDetails = () => {
  document.title = "Blog Details | Jobcy";

  const [blogDetails, setBlogDetails] = useState({});
  const [allBlogs, setAllBlogs] = useState([]);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firstrenderdata, setFirstrenderdata] = useState(false);

  const [components, setComponents] = useState([]);
  const pagesData = useSelector((state) => state.pagesDataReducer.pagesData);

  const componentDataArray = components?.[0]?.map(
    ({ _id, componentData, createdBy, createdAt }) => ({
      _id,
      value: [
        ...componentData?.map((item, index) =>
          index === 2 ? item.files : index === 3 ? item.options : item.value
        ),
        createdBy,
        new Date(createdAt).toISOString().split("T")[0], // Convert to 'YYYY-MM-DD' format
      ],
    })
  );

  const idtosent =
    Array.isArray(pagesData) &&
    pagesData.find((item) => item._id === blogsMapping.Post.id)?._id;

  useEffect(() => {
    // const token = localStorage.getItem("token");
    const token = process.env.REACT_APP_TOKEN;
    getcomponents(token, idtosent).then((data) => {
      setComponents(data);
      // setLoading(false);
    });
  }, [pagesData]);

  const { id } = useParams();
  const element = componentDataArray?.find((item) => item._id === id);

  useEffect(() => {
    if (
      componentDataArray?.length &&
      JSON.stringify(allBlogs) !== JSON.stringify(componentDataArray)
    ) {
      setAllBlogs(componentDataArray);
    }
  }, [componentDataArray, allBlogs]);

  const firstrender = () => {
    if (allBlogs?.length > 0 && !firstrenderdata) {
      setLoading(false); // Set loading to false after 2 seconds
    }
  };
  useEffect(() => {
    if (!firstrenderdata) {
      firstrender();
    }
  }, [allBlogs]);

  // useEffect(() => {
  //   const fetchBlog = async () => {
  //     magicJson.endpoint = "resources";
  //     magicJson.executor = "getResourcesById";
  //     magicJson.data = [{ id }];

  //     const reqData = new FormData();
  //     reqData.append("request", JSON.stringify(magicJson));

  //     const requestOptions = {
  //       method: "POST",
  //       headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  //       body: reqData,
  //     };

  //     const res = await fetch(process.env.REACT_APP_API_URL, requestOptions);
  //     const data = await res.json();
  //     setBlogDetails(data.data[0][0]);
  //     setComments(data.data[0][0]?.comments || []);
  //   };

  //   const fetchAllBlogs = async () => {
  //     magicJson.endpoint = "resources";
  //     magicJson.executor = "getResources";

  //     const reqData = new FormData();
  //     reqData.append("request", JSON.stringify(magicJson));
  //     const requestOptions = {
  //       method: "POST",
  //       headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  //       body: reqData,
  //     };

  //     const res = await fetch(process.env.REACT_APP_API_URL, requestOptions);
  //     const data = await res.json();
  //     setAllBlogs(data.data[0]);
  //   };

  //   fetchAllBlogs();
  //   fetchBlog();
  // }, [id]);

  const addComment = (newComment) => {
    setComments((prevComments) => [newComment, ...prevComments]);
  };

  if (loading) {
    return (
      <div className="loader">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <BlogTitle
            title={element?.value?.[1]}
            category={element?.value?.[0]}
          />
          <Row>
            <Col lg={8}>
              <div className="blog-post">
                {/* <BlogSwiper image={blogDetails.image} /> */}
                <Col lg={10} className="mb-4">
                  {/* <Card className="blog-grid-box blog-grid-box-style p-2">
                    <img
                      src={blogDetails.image?.url}
                      alt="image is not available"
                      className="object-fit-cover"
                      style={{ height: "400px" }}
                    />
                  </Card> */}
                </Col>

                <BlogColumn
                  author={element?.value?.[5]}
                  noOfComments={element?.length || 0}
                  createdAt={element?.value?.[6]?.slice(0, 10)}
                  content={element?.value?.[4]}
                  image={element?.value?.[2][0]}
                />
                {/* {blogDetails?.allowComments === true ? (
                  <div>
                    <BlogForm addComment={addComment} />
                    <BlogComments comments={comments} />
                  </div>
                ) : (
                  ""
                )} */}
                <BlogPost allBlogs={allBlogs} blogDetails={element} />
              </div>
            </Col>
            <Col lg={4} md={5}>
              <div className="sidebar ms-lg-4 ps-lg-4 mt-5 mt-lg-0">
                {/* <BlogCategory />
                <PopularPost />
                <TextWidget />
                <Archives />
                <Tags /> */}
                <SocialConnect />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default BlogDetails;

import React, { useState, useEffect, useContext } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import jobImage1 from "../../../assets/images/featured-job/img-01.png";
import jobImage2 from "../../../assets/images/featured-job/img-02.png";
import jobImage3 from "../../../assets/images/featured-job/img-03.png";
import jobImage4 from "../../../assets/images/featured-job/img-04.png";
import magicJson from "../../../magicJson/magicJson";
import akinoLogo from "../../../assets/images/logo/akino-logo.png";
import { addMinutes, formatDistanceToNow } from "date-fns";
import ApplyNowForm from "../../Jobs/JobList/applyNowform";
import ApiRequest from "../../../utils/apiRequest";
import { useSelector } from "react-redux";
import { UserContext } from "../../Alldata";
import homePageMapping from "../../../mappings/homePageMapping";

const apiRequest = new ApiRequest();

const RecentJobs = () => {
  // Apply Now Modal
  const pagesData = useSelector((state) => state.pagesDataReducer.pagesData);

  const { filteredrecentData } = useContext(UserContext);

  const myObject =
    Array.isArray(filteredrecentData) &&
    filteredrecentData.find((item) => item._id === homePageMapping.id)
      ?.componentData;

  const viewMoreButton =
    Array.isArray(myObject) &&
    myObject
      .find((item) => item._id === homePageMapping.newRandomJobs.id)
      ?.childComponents.find(
        (item) => item.id === homePageMapping.newRandomJobs.viewMoreButton.id
      )?.value;

  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);
  const [filteredrecentJobs, setfilteredrecentJobs] = useState([]);
  const [sortedJobs, setSortedJobs] = useState([]);
  const [errors, setErrors] = useState({});
  const [jobId, setJobId] = useState("");
  const [isApplyFormOpen, setIsApplyFormOpen] = useState(false);

  const openApplyForm = () => {
    setIsApplyFormOpen(true);
  };

  const closeApplyform = () => {
    setIsApplyFormOpen(false);
  };

  const updateModalStateByChild = (value) => {
    setIsApplyFormOpen(value);
  };

  const getJobs = async () => {
    magicJson.endpoint = "jobs";
    magicJson.executor = "getActiveJobs";

    const resp = await apiRequest.sendRequest(magicJson);
    if (!resp?.data[0]?.token) {
      setfilteredrecentJobs(resp?.data[0]);
    }
  };

  useEffect(() => {
    getJobs();
  }, [pagesData]);

  useEffect(() => {
    let arr = filteredrecentJobs;

    function parseDateTime(datetimeString) {
      // Replace space with T to fit ISO 8601 format
      if (datetimeString) {
        const isoDateTimeString = datetimeString.replace(" ", "T");
        return new Date(isoDateTimeString);
      }
    }

    if (Array.isArray(arr)) {
      arr.sort((a, b) => {
        const dateA = parseDateTime(a.createdAt);
        const dateB = parseDateTime(b.createdAt);
        return dateB - dateA; // Sort in descending order
      });

      setSortedJobs(arr.slice(0, 3));
    }
  }, [filteredrecentJobs, pagesData]);

  const handleApply = (id) => {
    openApplyForm();
    setJobId(id);
  };

  const getTimeAgo = (createdAt) => {
    const date = new Date(createdAt);

    // IST is GMT+5:30 which is 330 minutes ahead
    const dateIST = addMinutes(date, 330);

    // Get the "time ago" string
    return formatDistanceToNow(dateIST, { addSuffix: true });
  };

  const recentJob = [
    {
      id: 1,
      companyImg: jobImage1,
      jobDescription: "Web Developer",
      companyName: "Web Technology pvt.Ltd",
      location: "Oakridge Lane ssRichardson",
      salary: "1000-1200/m",
      fullTime: true,
      timing: "Full Time",
      catogary: "Recent Jobs",
      addclassNameBookmark: false,
      badges: [
        {
          id: 1,
          badgeclassName: "bg-info-subtle text-info",
          badgeName: "Private",
        },
      ],
      experience: "1 - 2 years",
      Notes: "languages only differ in their grammar.",
    },
    {
      id: 2,
      companyImg: jobImage2,
      jobDescription: "Business Associate",
      companyName: "Pixel Technology pvt.Ltd",
      location: "Dodge City, Louisiana",
      salary: "800-1800/m",
      partTime: true,
      timing: "Part Time",
      catogary: "Recent Jobs",
      addclassNameBookmark: true,
      badges: [
        {
          id: 1,
          badgeclassName: "bg-info-subtle text-info",
          badgeName: "Private",
        },
        {
          id: 2,
          badgeclassName: "bg-warning-subtle text-warning",
          badgeName: "Urgent",
        },
      ],
      experience: "0 - 1 years",
      Notes: "languages only differ in their grammar.",
    },
    {
      id: 3,
      companyImg: jobImage3,
      jobDescription: "Digital Marketing Manager",
      companyName: "Jobcy Technology Pvt.Ltd",
      location: "Phoenix, Arizona",
      salary: "1500-2400/m",
      freelancer: true,
      timing: "Freelancer",
      catogary: "Recent Jobs",
      addclassNameBookmark: true,
      badges: [
        {
          id: 1,
          badgeclassName: "bg-info-subtle text-info",
          badgeName: "Private",
        },
      ],
      experience: "0 - 1 years",
      Notes: null,
    },
    {
      id: 4,
      companyImg: jobImage4,
      jobDescription: "Product Director",
      companyName: "Creative Agency",
      location: "Escondido, California",
      salary: "1500-2400/m",
      fullTime: true,
      timing: "Full Time",
      catogary: "Recent Jobs",
      badges: [
        {
          id: 1,
          badgeclassName: "bg-warning-subtle text-warning",
          badgeName: "Urgent",
        },
      ],
      experience: "0 - 1 years",
      Notes: null,
    },
  ];

  const handleClick = () => {
    toast.remove();
    toast.error("Work in progress!");
  };

  return (
    <React.Fragment>
      {Array.isArray(sortedJobs) &&
        sortedJobs.map((filteredrecentJobsDetails, key) => (
          <div
            key={key}
            className={
              filteredrecentJobsDetails.addclassNameBookmark === true
                ? "job-box bookmark-post card mt-4"
                : "job-box card mt-4"
            }
          >
            {/* <div className="bookmark-label text-center" onClick={handleClick}>
            <Link to="#" className="align-middle text-white">
              <i className="mdi mdi-star"></i>
            </Link>
          </div> */}

            <div className="p-4">
              <Row>
                <Col md={2}>
                  <div className="text-center mb-4 mb-md-0">
                    <Link
                      to="/#"
                      onClick={(event) => {
                        event.preventDefault();
                        toast.error("Work in progress!");
                      }}
                    >
                      <img
                        src={akinoLogo}
                        alt="company-logo"
                        className="img-fluid rounded-3"
                      />
                    </Link>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="mb-2 mb-md-0">
                    <h5 className="fs-18 mb-0">
                      <Link
                        to={`/jobdetails/${filteredrecentJobsDetails._id}`}
                        className="text-dark"
                      >
                        {filteredrecentJobsDetails.title}
                      </Link>
                    </h5>
                    <p className="text-muted fs-14 mb-0">
                      {filteredrecentJobsDetails.companyName}
                    </p>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="d-flex mb-2">
                    <div className="flex-shrink-0">
                      <i className="mdi mdi-map-marker text-primary me-1"></i>
                    </div>
                    <p className="text-muted mb-0">
                      {[
                        filteredrecentJobsDetails.city,
                        filteredrecentJobsDetails.state,
                        filteredrecentJobsDetails.country,
                      ]
                        .filter(Boolean) // Filter out empty values
                        .map((part, index, array) => (
                          // Add <br /> between parts except the last one
                          <React.Fragment key={index}>
                            {part}
                            {index < array.length - 1 && ","}
                            {index < array.length - 1 && <br />}
                          </React.Fragment>
                        ))}
                    </p>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="d-flex mb-0">
                    <div className="flex-shrink-0">
                      <i className="uil uil-clock-three text-primary me-1"></i>
                    </div>
                    <p className="text-muted mb-0">
                      {getTimeAgo(filteredrecentJobsDetails.createdAt)}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="p-3 bg-light-gray">
              <Row className="justify-content-between">
                <Col md={4}>
                  <div>
                    <p className="text-muted mb-0">
                      <span className="text-dark">Experience: </span>
                      {filteredrecentJobsDetails.minExp}-
                      {filteredrecentJobsDetails.maxExp} Years
                    </p>
                  </div>
                </Col>
                <Col lg={2} md={3}>
                  <div>
                    <Link
                      to="#applyNow"
                      onClick={() => handleApply(filteredrecentJobsDetails._id)}
                      className="primary-link"
                    >
                      Apply Now <i className="mdi mdi-chevron-double-right"></i>
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        ))}
      <div className="text-center mt-4 pt-2">
        <Link to="/jobs" className="btn btn-primary">
          {viewMoreButton}
          <i className="uil uil-arrow-right"></i>
        </Link>
      </div>
      {isApplyFormOpen && (
        <ApplyNowForm
          show={isApplyFormOpen}
          onClose={closeApplyform}
          updateModalStateByChild={updateModalStateByChild}
          jobId={jobId}
        ></ApplyNowForm>
      )}
    </React.Fragment>
  );
};

export default RecentJobs;

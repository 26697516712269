import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../Alldata";
import { Col, Row, Container, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import magicJson from "../../magicJson/magicJson";
import homePageMapping from "../../mappings/homePageMapping";
import ApiRequest from "../../utils/apiRequest";

const apiRequest = new ApiRequest();

const Blog = () => {
  const [blogDetails, setBlogDetails] = useState([]);

  const { filteredrecentData, name } = useContext(UserContext);
  const myObject =
    Array.isArray(filteredrecentData) &&
    filteredrecentData.find((item) => item._id === homePageMapping.id)
      ?.componentData;

  const title =
    Array.isArray(myObject) &&
    myObject
      .find((item) => item._id === homePageMapping.QuickCareerTips1.id)
      ?.childComponents.find(
        (item) => item.id === homePageMapping.QuickCareerTips1.title.id
      )?.value;

  const description =
    Array.isArray(myObject) &&
    myObject
      .find((item) => item._id === homePageMapping.QuickCareerTips1.id)
      ?.childComponents.find(
        (item) => item.id === homePageMapping.QuickCareerTips1.description.id
      )?.value;

  useEffect(() => {
    const fetchAllBlogs = async () => {
      try {
        magicJson.endpoint = "resources";
        magicJson.executor = "getResources";

        const resp = await apiRequest.sendRequest(magicJson);

        // Sort blogs by date in descending order (latest first)
        const sortedBlogs = resp?.data[0].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        // Set sorted blogs
        setBlogDetails(sortedBlogs);
      } catch (error) {
        console.error("Something went wrong!");
      }
    };

    fetchAllBlogs();
  }, []);

  return (
    <React.Fragment>
      <section className="section bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="section-title text-center mb-5">
                {/* <h3 className="title mb-3">Quick Career Tips</h3> */}
                <h3 className="title mb-3">{title}</h3>
                <p className="text-muted">
                  {/* Post a job to tell us about your project. We'll quickly match
                  you with the right freelancers. */}
                  {description}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            {blogDetails &&
              blogDetails.length > 0 &&
              blogDetails.slice(0, 3).map((blog, key) => {
                const email = blog.createdBy;
                const truncatedEmail =
                  email.length > 30 ? `${email.slice(0, 30)}...` : email;
                return (
                  <Col lg={4} className="mb-4" key={key}>
                    <Card
                      className="blog-grid-box p-2"
                      style={{ height: "27rem" }}
                    >
                      <img
                        src={blog.image.url}
                        alt="image is not available"
                        className="object-fit-cover"
                        style={{ height: "200px" }}
                      />
                      <CardBody className="position-relative">
                        <ul className="list-inline d-flex justify-content-between mb-3">
                          <li className="list-inline-item">
                            <p className="text-muted mb-0">
                              {/* <Link
                                to={`/blogdetails/${blog._id}`}
                                className="text-muted fw-medium"
                              >
                                {blog.createdBy.length > 30
                                  ? `${blog.createdBy.slice(0, 30)}...`
                                  : blog.createdBy}
                              </Link> */}
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip">{email}</Tooltip>
                                }
                              >
                                <Link
                                  to={`/blogdetails/${blog._id}`}
                                  className="text-muted fw-medium"
                                >
                                  {truncatedEmail}
                                </Link>
                              </OverlayTrigger>

                              <p>{blog.createdAt?.slice(0, 10)}</p>
                            </p>
                          </li>
                          <li className="list-inline-item">
                            <p className="text-muted mb-0">
                              <i className="mdi mdi-eye"></i>
                              {blog.views ? blog.views : 0}
                            </p>
                          </li>
                        </ul>
                        <Link
                          to={`/blogdetails/${blog._id}`}
                          className="primary-link"
                        >
                          <h6 className="fs-17">{blog.title}</h6>
                        </Link>
                        <p
                          className="text-muted"
                          dangerouslySetInnerHTML={{
                            __html:
                              blog.content?.length > 80
                                ? `${blog.content?.slice(0, 80) + "..."}`
                                : blog.content,
                          }}
                        ></p>
                        <div>
                          <Link
                            to={`/blogdetails/${blog._id}`}
                            className="form-text text-primary position-absolute bottom-0"
                            style={{ marginBottom: "10px" }}
                          >
                            Read More <i className="uil uil-angle-right-b"></i>
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Blog;

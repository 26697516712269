import React, { useState, useEffect } from "react";

import {
  Container,
  Row,
  Col,
  Nav,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";

//About Images
import testImg01 from "../../../assets/images/about/img-02.jpg";
import testImg02 from "../../../assets/images/about/img-03.jpg";
import testImg03 from "../../../assets/images/about/img-04.jpg";
import testImg04 from "../../../assets/images/about/img-05.jpg";
import toast from "react-hot-toast";

const CompanyTestimonal = ({ testimonialSectionData }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [lastFourImages, setLastFourImages] = useState([]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleClick = (link, event) => {
    if (link === "#") {
      toast.remove();
      toast.error("Work in progress!");
      event.preventDefault();
    }
  };

  useEffect(() => {
    const images = testimonialSectionData?.testimonialImages;
    if (images) {
      setLastFourImages(images.slice(-4));
      console.log("Last 4 images: ", images.slice(-4));
    }
  }, [testimonialSectionData]);

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="align-items-center">
            <Col lg={5}>
              <div className="section-title mb-5 mb-lg-0">
                <h4 className="title mb-4">
                  {testimonialSectionData.testimonialTitle}
                </h4>
                <p
                  className="text-muted"
                  dangerouslySetInnerHTML={{
                    __html: testimonialSectionData.testimonialDescription,
                  }}
                />
                <div className="d-flex align-items-center justify-content-between mt-4">
                  <div>
                    <Link
                      to="#"
                      className="btn btn-outline-primary"
                      onClick={(event) => handleClick("#", event)}
                    >
                      Learn More <i className="uil uil-angle-right-b ms-1"></i>
                    </Link>
                  </div>
                  <ul className="list-inline about-social-menu mb-0">
                    <li className="list-inline-item">
                      <Link to="#" onClick={(event) => handleClick("#", event)}>
                        <i className="uil uil-facebook-f"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#" onClick={(event) => handleClick("#", event)}>
                        <i className="uil uil-twitter"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#" onClick={(event) => handleClick("#", event)}>
                        <i className="uil uil-whatsapp"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#" onClick={(event) => handleClick("#", event)}>
                        <i className="uil uil-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={7}>
              <Row>
                <Col md={3}>
                  <Nav
                    tabs
                    className="about-testimonial-menu nav flex-sm-column flex-row nav-pills me-3"
                  >
                    {/* <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      <div className="position-relative">
                        <img
                          src={
                            testimonialSectionData.testimonialImages?.[1]?.url
                          }
                          alt=""
                          className="rounded"
                        />
                        <div className="about-testi-bg-overlay">
                          <i className="mdi mdi-plus-circle-outline text-white fs-3"></i>
                        </div>
                      </div>
                    </NavLink>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      <div className="position-relative">
                        <img
                          src={
                            testimonialSectionData.testimonialImages?.[2]?.url
                          }
                          alt=""
                          className="rounded"
                        />
                        <div className="about-testi-bg-overlay">
                          <i className="mdi mdi-plus-circle-outline text-white fs-3"></i>
                        </div>
                      </div>
                    </NavLink>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        toggle("3");
                      }}
                    >
                      <div className="position-relative">
                        <img
                          src={
                            testimonialSectionData.testimonialImages?.[3]?.url
                          }
                          alt=""
                          className="rounded"
                        />
                        <div className="about-testi-bg-overlay">
                          <i className="mdi mdi-plus-circle-outline text-white fs-3"></i>
                        </div>
                      </div>
                    </NavLink>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggle("4");
                      }}
                    >
                      <div className="position-relative">
                        <img
                          src={
                            testimonialSectionData.testimonialImages?.[4]?.url
                          }
                          alt=""
                          className="rounded"
                        />
                        <div className="about-testi-bg-overlay">
                          <i className="mdi mdi-plus-circle-outline text-white fs-3"></i>
                        </div>
                      </div>
                    </NavLink> */}
                    {lastFourImages.map((image, index) => (
                      <NavLink
                        key={index}
                        className={classnames({
                          active: activeTab === `${index + 1}`,
                        })}
                        onClick={() => toggle(`${index + 1}`)}
                      >
                        <div className="position-relative">
                          <img src={image.url} alt="" className="rounded" />
                          <div className="about-testi-bg-overlay">
                            <i className="mdi mdi-plus-circle-outline text-white fs-3"></i>
                          </div>
                        </div>
                      </NavLink>
                    ))}
                  </Nav>
                </Col>

                <Col md={9}>
                  <TabContent
                    className="about-tab-content h-100"
                    activeTab={activeTab}
                  >
                    {lastFourImages.map((image, index) => (
                      <TabPane
                        key={index}
                        tabId={`${index + 1}`}
                        className="fade rounded-3 show"
                        style={{ backgroundImage: `url(${image.url})` }}
                      ></TabPane>
                    ))}
                  </TabContent>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CompanyTestimonal;

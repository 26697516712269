import React from "react";
import { Col, Row } from "reactstrap";

const BlogTitle = ({ title, category }) => {
  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col lg={7}>
          <div className="text-center mb-5">
            <p className="text-capitalize fw-semibold mb-0">{category}</p>
            <h3 className="text-capitalize">{title}</h3>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BlogTitle;

// const aboutusPageMapping = {
//   id: "6731af067128df65b0442da8",

//   breadcrumb: {
//     id: "8d6c4088-9256-44cc-a384-b57cbef5c7c1",
//     breadcrumb1: { id: "869ff7dc-125c-415a-8bdb-0f627a1823d3" },
//     breadcrumb2: { id: "c81efc91-f41e-4177-9b7c-61d33a920397" },
//     breadcrumb3: { id: "8cb4cd8a-6101-46db-973e-5fe4dbb4ce77" },
//   },

//   aboutus: {
//     id: "fdf6b499-27e7-4410-af63-a9e3d4778f27",
//   },
// };

const aboutusPageMapping = {
  id: "6731af067128df65b0442da8",
  aboutUs: {
    id: "fdf6b499-27e7-4410-af63-a9e3d4778f27",
    aboutUsPageTitle: {
      id: "48a76d36-bf5c-455d-a3e1-d3ab7e5ebe74",
    },
    aboutUs: {
      id: "00862f0b-86d5-4c21-9f91-a698a68b7f3f",
    },
    "title-1": {
      id: "642461ae-fffa-4da7-8b63-99144af4116b",
    },
    description: {
      id: "19c5d093-7375-442a-852f-97e808068a67",
    },
    image: {
      id: "e5bcb731-886d-438a-a556-ebaf67daecbe",
    },
    link: {
      id: "e7f442d7-a70a-40db-ad7d-1645af7cb2a1",
      linkName: {
        id: "5b6f7ee3-2c4c-402a-bf83-ac578a26734c",
      },
      url: {
        id: "4b968df4-d4b3-422e-8e53-b1eaf1692e7a",
      },
    },
    lhsList: {
      id: "1f108668-8dfa-4b68-b855-18a969b86ad3",
    },
    rhsList: {
      id: "f57949ec-822f-4285-8335-594ff41850c9",
    },
  },
  keyFeatures: {
    id: "548d9c64-8a55-49f2-a271-0b62a688482a",
    keyFeatures: {
      id: "09eee548-81d8-4863-b294-3f811a92c51e",
    },
    "title-1": {
      id: "a1adcb53-2676-458c-af19-01f2e2e15b48",
    },
    keyFeaturesName: {
      id: "c746c26e-5070-413e-aa1f-55c08e80484a",
      keyFeatureName: {
        id: "eef9543b-067f-44a9-a98d-fa097af6d752",
      },
      keyFeatureImage: {
        id: "67684ff2-af85-4c08-b9f7-789d8251c06b",
      },
    },
  },
  freeTrial: {
    id: "eb0c0461-c09e-4c84-a228-1a4ee7a1be50",
    titleName: {
      id: "26561b5a-7ccb-4eb4-a8c6-d0ed0df9676d",
    },
    description: {
      id: "87a8e3a7-6118-4198-b590-6935490ebe28",
    },
    link1: {
      id: "02398c5c-908a-4d84-ac9f-056ad961316b",
      linkName: {
        id: "5b6f7ee3-2c4c-402a-bf83-ac578a26734c",
      },
      url: {
        id: "4b968df4-d4b3-422e-8e53-b1eaf1692e7a",
      },
      logo: {
        id: "7fa23c03-32d6-457f-9890-3c02f9427655",
      },
    },
    link2: {
      id: "c2b32915-dc74-4d72-9d16-50820e91b07e",
      linkName: {
        id: "5b6f7ee3-2c4c-402a-bf83-ac578a26734c",
      },
      url: {
        id: "4b968df4-d4b3-422e-8e53-b1eaf1692e7a",
      },
      logo: {
        id: "61a5b98d-eb06-4a4f-bfcc-31c7c1149757",
      },
    },
  },
  companyTestimonial: {
    id: "87b04515-ff21-430d-8623-85d9b87d2c60",
    titleName: {
      id: "3dd61db0-5e6f-4f6a-8ed0-f33f7b481db8",
    },
    description: {
      id: "cd717a70-a977-434e-981a-d3b986db69a2",
    },
    images: {
      id: "2721f14d-7178-42e7-aeed-dcb22cde673c",
    },
    socialMediaOption: {
      id: "19d689ca-98c3-435f-a038-924bcc63ca3c",
      "link-1": {
        id: "2e2c10f0-2cfe-40e2-b85f-68dad1d6ffd0",
        logo: {
          id: "139b0010-3eaa-4e83-87c7-37be146db81f",
        },
        urlOfSocialMedia: {
          id: "a5297ade-f803-4256-b500-a0c65a353e63",
        },
      },
      "link-2": {
        id: "35b0de67-25a9-4bb3-9445-e283b834966b",
        logo: {
          id: "48880b7c-6fd9-486c-9024-70a7403c3623",
        },
        urlOfSocialMedia: {
          id: "29a2aa32-0536-4b2c-ad12-a286d0f0eda6",
        },
      },
      "link-3": {
        id: "855d8c02-5078-4e45-bb01-4bd08bf4e57c",
        logo: {
          id: "9fe0ae7d-fd2f-48d4-8ffc-3a0ef32f38b2",
        },
        urlOfSocialMedia: {
          id: "6ad4da0c-6ad2-4cac-aeb2-1e24f365e50c",
        },
      },
      "link-4": {
        id: "8348c1a4-4d9f-4da3-8508-736c1a124895",
        logo: {
          id: "a7048bf5-6d17-478b-aa69-aab963f88d35",
        },
        urlOfSocialMedia: {
          id: "4ca91cd9-6d13-45ae-b0a0-150ca7ef97ee",
        },
      },
    },
    link: {
      id: "732d3a2b-5e30-4668-87a8-801fe637883c",
      linkName: {
        id: "5b6f7ee3-2c4c-402a-bf83-ac578a26734c",
      },
      url: {
        id: "4b968df4-d4b3-422e-8e53-b1eaf1692e7a",
      },
    },
  },
  footer: {
    id: "eaa666d7-b111-401a-8564-68a897c098ab",
    sec1: {
      id: "21eff39a-b5fc-4a0d-a8a2-fa3d4d5f0608",
      title: {
        id: "f8c062ad-1530-4e3d-a957-94aabaf66009",
      },
      subtitle: {
        id: "46a901cf-c0c4-4c65-8003-9cc0b41103b7",
      },
      enterEmail: {
        id: "6594dd3b-b95a-4691-af53-f0123bc9d1f8",
      },
    },
    jobcy: {
      id: "e4b7b339-0c36-45b2-bfb9-d8f6b2bb925e",
      title: {
        id: "c81ef367-a5c4-4393-9d25-c34b6fe9539c",
      },
      description: {
        id: "2b66f6fc-3d85-4234-8867-2755bfbbbc8f",
      },
      "followUsOn:": {
        id: "edf12cd4-8c81-4bba-b6e6-76c82898949b",
      },
      link1: {
        id: "9f40373e-6fc4-4d80-a783-6eb5a4b538f8",
      },
      link2: {
        id: "ac085cba-80f8-40f0-b1b2-b5f43be7e122",
      },
      link3: {
        id: "5b93401a-e58f-4dc5-ba9b-7d6b721c1914",
      },
      link4: {
        id: "953c178d-8bd4-4086-ad52-cf8ab1a0f8c0",
      },
    },
    company: {
      id: "f3ca600f-7f2e-4f08-96b0-48f38317e921",
      aboutUs: {
        id: "3a035014-2ec4-46a1-b735-f80e28076b7c",
      },
      contactUs: {
        id: "aa49cae3-60ad-447b-ba32-05a187494a9d",
      },
      services: {
        id: "2e949686-7d0f-4169-8832-28dc770381b4",
      },
      blog: {
        id: "145606d7-de64-488e-bb91-ca54c2fd43c6",
      },
      team: {
        id: "7de6759f-0d15-4481-a128-00e5cc35e498",
      },
      pricing: {
        id: "0f067212-23ad-4c78-ba04-2227583294e9",
      },
    },
    forJobs: {
      id: "e9e4ff8a-9214-47a0-b171-bb233c490e45",
      browseCategories: {
        id: "69f6aa78-1a71-48ef-81d7-829430a49196",
      },
      browseJobs: {
        id: "8d34bf07-3a98-4057-9913-8b49474666fb",
      },
      jobDetails: {
        id: "9470178f-c2f9-4d37-a86a-e9c17d97abf9",
      },
      bookmarkJobs: {
        id: "27c52666-e454-4213-bcdc-65ea6f91dcd5",
      },
    },
    forCandidates: {
      id: "ec951f87-13f2-4ebd-b93c-b472416e26ab",
      candidateList: {
        id: "04e435db-247a-443b-bd8d-e06210b2ad4b",
      },
      candidateGrid: {
        id: "fc635fad-2c57-4ebb-8813-1b19ba93801b",
      },
      candidateDetails: {
        id: "25a117cd-ee64-4c95-90cf-a01c293efa51",
      },
    },
    support: {
      id: "278645ea-b115-4f97-8123-6e84509937fe",
      helpCenter: {
        id: "4da32ccb-26c8-4c93-932c-18217d72db37",
      },
      "faq'S": {
        id: "4de4d243-aa5b-42c8-9bcf-0b66245aad17",
      },
      privacyPolicy: {
        id: "e873d620-4d6e-4f59-a807-1f61963b13be",
      },
    },
    "2024©Jobcy": {
      id: "38c0fee7-0b4c-4a0c-88f0-5a1f9f81bc9a",
    },
  },
  breadcrumb: {
    id: "8d6c4088-9256-44cc-a384-b57cbef5c7c1",
    breadcrumb1: {
      id: "869ff7dc-125c-415a-8bdb-0f627a1823d3",
    },
    breadcrumb2: {
      id: "c81efc91-f41e-4177-9b7c-61d33a920397",
    },
    breadcrumb3: {
      id: "8cb4cd8a-6101-46db-973e-5fe4dbb4ce77",
    },
  },
  numbers: {
    id: "33b275fb-4a7b-411b-8655-330ecbb054fa",
    titleName: {
      id: "568fe507-02d9-4a52-8eea-540fa0b2a86f",
    },
    numbers: {
      id: "82bde076-b52e-4987-9c6f-319e04ea719e",
    },
  },
};

export default aboutusPageMapping;

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Col, Input, Row } from "reactstrap";
import magicJson from "../../../magicJson/magicJson.js";

const BlogForm = ({ addComment }) => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      magicJson.endpoint = "resources";
      magicJson.executor = "createComment";
      magicJson.data = [
        {
          resId: id,
          name,
          email,
          comment,
          createdAt: new Date().toISOString().slice(0, 19).replace("T", " "),
        },
      ];

      const reqData = new FormData();

      reqData.append("request", JSON.stringify(magicJson));

      const requestOptions = {
        method: "POST",
        // headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN}` },
        body: reqData,
      };

      const res = await fetch(process.env.REACT_APP_API_URL, requestOptions);
      const data = await res.json();

      if (data.data[0] === false) {
        toast.remove();
        toast.error("Error Occurred!");
      } else {
        toast.remove();
        toast.success("Comment added successfully!");
        addComment({
          name,
          email,
          comment,
          createdAt: magicJson.data[0].createdAt,
        });
        setName("");
        setEmail("");
        setComment("");
      }
    } catch (error) {
      toast.remove();
      toast.error("Error Occurred!");
      console.error("Error adding comment:", error);
    }
  };

  return (
    <React.Fragment>
      <Form className="contact-form mt-5" onSubmit={handleSubmit}>
        <h5 className="border-bottom pb-3">Leave a Comment</h5>
        <Row className="mt-4">
          <Col lg={6}>
            <div className="position-relative mb-3">
              <label htmlFor="name" className="form-label">
                Name
                <span style={{ color: "red" }}>*</span>
              </label>
              <Input
                name="name"
                id="name"
                value={name}
                type="text"
                className="form-control"
                placeholder="Enter your name"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="position-relative mb-3">
              <label htmlFor="email" className="form-label">
                Email address
              </label>
              <span style={{ color: "red" }}>*</span>
              <Input
                name="email"
                id="email"
                value={email}
                type="email"
                className="form-control"
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </Col>
          {/* <Col lg={12}>
            <div className="position-relative mb-3">
              <label htmlFor="subject" className="form-label">
                Subject
              </label>
              <Input
                name="subject"
                id="subject"
                type="text"
                className="form-control"
                placeholder="Subject"
              />
            </div>
          </Col> */}
          <Col lg={12}>
            <div className="position-relative mb-3">
              <label htmlFor="comments" className="form-label">
                Comment
              </label>
              <span style={{ color: "red" }}>*</span>
              <textarea
                name="comments"
                id="comments"
                value={comment}
                rows="4"
                className="form-control"
                placeholder="Enter your comment"
                onChange={(e) => setComment(e.target.value)}
                required
              ></textarea>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="text-end">
            <button type="submit" className="btn btn-primary btn-hover">
              Send Meassage <i className="uil uil-message ms-1"></i>
            </button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default BlogForm;

import React from "react";
import { Container, Row, Col } from "reactstrap";
import CountUp from "react-countup";

const Counter = ({counterSectionData}) => {
  return (
    <React.Fragment>
      <section className="section bg-light">
        <Container>
          <Row>
            <Col lg={3} md={6}>
              <div className="counter-box mt-3">
                <div className="counters counter_custom text-center">
                  <CountUp
                    end={counterSectionData.counters?.[3]?.count}
                    duration={1}
                    className="counter mb-0"
                  ></CountUp>
                  <h6 className="fs-16 mt-3 ">{counterSectionData.counters?.[3]?.countTitle}</h6>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="counter-box mt-3">
                <div className="counters counter_custom text-center">
                  <CountUp
                    end={counterSectionData.counters?.[2]?.count}
                    duration={1}
                    className="counter mb-0"
                  ></CountUp>
                  <h6 className="fs-16 mt-3">{counterSectionData.counters?.[2]?.countTitle}</h6>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="counter-box mt-3">
                <div className="counters counter_custom text-center">
                  <CountUp
                    end={counterSectionData.counters?.[1]?.count}
                    decimals={2}
                    duration={1}
                    className="counter mb-0"
                  ></CountUp>
                  <h6 className="fs-16 mt-3">{counterSectionData.counters?.[1]?.countTitle}</h6>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="counter-box mt-3">
                <div className="counters counter_custom text-center">
                  <CountUp
                    end={counterSectionData.counters?.[0]?.count}
                    duration={1}
                    className="counter mb-0"
                  ></CountUp>
                  <h6 className="fs-16 mt-3">{counterSectionData.counters?.[0]?.countTitle}</h6>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Counter;

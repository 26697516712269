const blogsMapping = {
    id: "6731a1047128df65b0442da7",

    Post: {
        id: "67319c727128df65b0442da4",
    },

    






  };
  
  export default blogsMapping;
  
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import store from "./redux/store";
import { AppContextProvider } from "./AppContext/AppContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.Fragment>
    <Provider store={store}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <AppContextProvider>
          <Toaster
            toastOptions={{
              position: "top-center",
              duration: 3500,
              style: {
                margin: "6rem 0rem 0rem 0rem",
              },
            }}
          />
          <App />
        </AppContextProvider>
      </BrowserRouter>
    </Provider>
  </React.Fragment>
);

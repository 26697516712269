import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import JobDetailsDescription from "./JobDetailsDescription";
import JobVacancyPost from "./JobVacancyPost";
import RightSideContent from "./RightSideContent";
import Section from "./Section";
import { useParams } from "react-router-dom";
import magicJson from "../../../magicJson/magicJson";
import ApiRequest from "../../../utils/apiRequest";

const apiRequest = new ApiRequest();

const JobDetails = () => {
  document.title = "Job Details | Jobcy";

  const [job, setJob] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [firstrenderdata, setFirstrenderdata] = useState(false);

  useEffect(() => {
    async function getJobById() {
      magicJson.endpoint = "jobs";
      magicJson.executor = "getJobsById";
      magicJson.data = [{ id }];

      const resp = await apiRequest.sendRequest(magicJson);
      setJob(resp?.data[0][0]);
      // setLoading(false);
    }

    getJobById();
  }, [id]);

  const firstrender = () => {
    if (job?.length > 0 && !firstrenderdata) {
      setFirstrenderdata(true);
      setLoading(false); // Set loading to false after 2 seconds
    }
  };
  useEffect(() => {
    if (!firstrenderdata) {
      firstrender();
    }
  }, [job]);

  if (loading) {
    return (
      <div className="loader">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <Row>
            <Col lg={8}>
              <JobDetailsDescription job={job} />
              <JobVacancyPost />
            </Col>
            <Col lg={4} className="mt-4 mt-lg-0">
              <RightSideContent job={job} />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JobDetails;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Routes from "./Routes/index";
import { setPagesData } from "./redux/actions";
import { setComponentData } from "./redux/actions";
import magicJson from "./magicJson/magicJson";
import "./assets/scss/themes.scss";
import ApiRequest from "./utils/apiRequest";
import { useSelector } from "react-redux";

const apiRequest = new ApiRequest();

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [token, setToken] = useState(localStorage.getItem("token"));
  const fetchPagesData = async () => {
    magicJson.endpoint = "contentTypeBuilder";
    magicJson.executor = "getPages";
    magicJson.metadata.data.options.sortBy = { sort: { _id: 1 } };

    const result = await apiRequest.sendRequest(magicJson);
    if (result) {
      setToken(localStorage.getItem("token"));

      if (!result.data[0]?.token) {
        dispatch(setPagesData(result.data[0]));
      }
    } else {
      localStorage.clear();
    }
  };

  const fetchComponentsData = async () => {
    magicJson.endpoint = "contentTypeBuilder";
    magicJson.executor = "getComponents";
    magicJson.metadata.data.options.sortBy = { sort: { _id: 1 } };

    const result = await apiRequest.sendRequest(magicJson);
    if (result) {
      setToken(localStorage.getItem("token"));

      if (!result.data[0]?.token) {
        dispatch(setComponentData(result.data[0]));
      }
    } else {
      localStorage.clear();
    }
  };

  const getMappingData = async (pageId) => {
    magicJson.endpoint = "contentTypeBuilder";
    magicJson.executor = "getPageMapping";
    magicJson.data = [{pageId}];

    const result = await apiRequest.sendRequest(magicJson);

    const resp = result.data[0];
  }

  useEffect(() => {
    fetchPagesData();
    fetchComponentsData()
    getMappingData("6718a55893b5f244673f47ec");
  }, [token, location.pathname]);

  if (!token) {
    return null;
  }

  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
};

export default App;

import React from "react";
import { Link } from "react-router-dom";
import { addMinutes, formatDistanceToNow } from "date-fns";

const BlogComments = ({ comments }) => {
  const getTimeAgo = (createdAt) => {
    const date = new Date(createdAt);

    // IST is GMT+5:30 which is 330 minutes ahead
    const dateIST = addMinutes(date, 330);

    // Get the "time ago" string
    return formatDistanceToNow(dateIST, { addSuffix: true });
  };

  return (
    <React.Fragment>
      <h5 className="border-bottom pb-3 mt-5">
        Comments ({comments.length || 0})
      </h5>
      <div className="mt-4">
        {comments.map((comment, key) => (
          <div
            className="d-sm-flex align-items-top mb-2 border border-secondary-subtle rounded-2 p-3"
            key={key}
          >
            <div className="flex-grow-1 ms-sm-3">
              <small className="float-end fs-12 text-muted me-2">
                <i className="uil uil-clock"></i>
                {getTimeAgo(comment.createdAt)}
              </small>
              <Link to="#" className="primary-link">
                <h6 className="fs-16 mt-sm-0 mt-3 mb-0">{comment.name}</h6>
              </Link>
              <p className="text-muted fs-14 mb-0">
                {comment.createdAt.slice(0, 10)}
              </p>

              <p className="fst-italic mb-0 mt-3">{comment.comment}</p>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default BlogComments;

import React from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

const BlogColumn = ({ author, noOfComments, createdAt, content, image }) => {
  const navigate = useNavigate();

  const handleClick = (link, event) => {
    if (link === "#") {
      toast.remove();
      toast.error("Work in progress!");
      event.preventDefault();
    }
  };
  const handleauthorclick = () => {
    navigate("/blogauther"); // Ensure this matches the route path
  };

  return (
    <React.Fragment>
      <ul className="list-inline mb-0 mt-3 text-muted">
        <li className="list-inline-item">
          <div className="d-flex align-items-center">
            {/* <div className="flex-shrink-0">
              <img
                src={userImage3}
                alt=""
                className="avatar-sm rounded-circle"
              />
            </div> */}
            <div className="ms-3">
              <Link
                to="#"
                className="primary-link"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default link behavior
                  handleauthorclick();
                }}
              >
                {/* <Link to="#" className="primary-link" onClick={handleClick}> */}
                {/* <Link to="/blogauther" className="primary-link"> */}
                <h6 className="mb-0">By {author}</h6>
              </Link>
            </div>
          </div>
        </li>
        <li className="list-inline-item">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className="uil uil-calendar-alt"></i>
            </div>
            <div className="ms-2">
              <p className="mb-0"> {createdAt}</p>
            </div>
          </div>
        </li>
        <li className="list-inline-item">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className="uil uil-comments-alt"></i>
            </div>
            <div className="ms-2 flex-grow-1">
              <p className="mb-0"> {noOfComments} Comments</p>
            </div>
          </div>
        </li>
      </ul>
      {image && image.url && image.url.length && (
        <div className="blog-image" style={{ maxHeight: "25%" }}>
          <img
            src={image?.url}
            alt="img"
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          />
        </div>
      )}
      <div className="mt-4">
        <div
          className="text-muted"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
        {/* <p className="text-muted mb-4">
          Home renovations, especially those involving plentiful of demolition
          can be a very dusty affair. The same is true as we experience the
          emotional sensation of stress from our first instances of social
          rejection ridicule. We quickly learn to fear and thus automatically
          avoid potentially stressful situations of all kinds, including the
          most common of all making mistakes.
        </p> */}
        {/* <figure className="blog-blockquote text-center">
          <blockquote className="blockquote">
            <p className="fs-17">
              "A business consulting agency is involved in the planning,
              implementation, and education of businesses."
            </p>
          </blockquote>
          <figcaption className="blockquote-footer fs-15 mb-4">
            Creative Agency
            <cite title="Source Title" className="text-primary fw-semibold">
              
              Alice Mellor
            </cite>
          </figcaption>
        </figure>
        <p className="text-muted">
          Whether article spirits new her covered hastily sitting her. Money
          witty books nor son add. Chicken age had evening believe but proceed
          pretend mrs. At missed advice my it no sister. Miss told ham dull knew
          see she spot near can. Spirit her entire her called.
        </p>
        <p className="text-muted">
          The advantage of its Latin origin and the relative meaninglessness of
          Lorum Ipsum is that the text does not attract attention to itself or
          distract the viewer's attention from the layout.
        </p>
        <div className="d-flex align-items-center mb-4">
          <div className="flex-shrink-0">
            <b>Tags:</b>
          </div>
          <div className="flex-grow-1 ms-2 d-flex flex-wrap align-items-start gap-1">
            <Link
              to="#"
              className="badge bg-success-subtle text-success mt-1 fs-14"
            >
              Business
            </Link>
            <Link
              to="#"
              className="badge bg-success-subtle text-success mt-1 fs-14"
            >
              design
            </Link>
            <Link
              to="#"
              className="badge bg-success-subtle text-success mt-1 fs-14"
            >
              Creative
            </Link>
            <Link
              to="#"
              className="badge bg-success-subtle text-success mt-1 fs-14"
            >
              Event
            </Link>
          </div>
        </div> */}
        <ul className="blog-social-menu list-inline mb-0 text-end">
          <li className="list-inline-item">
            <b>Share post:</b>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className="social-link bg-primary-subtle text-primary"
              onClick={(event) => handleClick("#", event)}
            >
              <i className="uil uil-facebook-f"></i>
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className="social-link bg-success-subtle text-success"
              onClick={(event) => handleClick("#", event)}
            >
              <i className="uil uil-whatsapp"></i>
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className="social-link bg-blue-subtle text-blue"
              onClick={(event) => handleClick("#", event)}
            >
              <i className="uil uil-linkedin-alt"></i>
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className="social-link bg-danger-subtle text-danger"
              onClick={(event) => handleClick("#", event)}
            >
              <i className="uil uil-envelope"></i>
            </Link>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default BlogColumn;
